import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useIdleTimer } from 'react-idle-timer';

import { RootState } from '../../redux/store';
import { checkInActions } from '../../redux/CheckIn/checkIn.slice';
import { fetchRegistrationDetails } from '../../redux/CheckIn/fetchRegistrationDetails';
import { fetchInsuranceDetails } from '../../redux/CheckIn/fetchInsuranceDetails';
import { Spinner } from '../../components/Spinner';
import { Languages } from '../../types/enums';
import { LanguagePage } from '../../Views/LanguagePage';
import { PatientDetails } from '../../Views/PatientDetails';
import { CheckinConfirmation } from '../../Views/CheckinConfirmation';
import { ErrorScreen } from '../../Views/ErrorScreen';
import { HouseholdDetails } from '../../Views/HouseholdDetails';
import { IdentityCardScanner } from '../../Views/IdentityCardScanner';
import errorMessages from '../../Views/ErrorScreen/messages';
import { CatchmentAreaPage } from '../../Views/CatchmentArea';
import { TopBar } from '../../components/TopBar';
import { getPatientData } from '../../redux/getPatientData';
import { getHealthCentreId } from '../../redux/HealthCentre/getHealthCentreId';
import { assertExhaustive } from '../assertExhaustive';

import { handleIdleState, handleRestartRegistration, initCheckinProcess } from './CheckInProcessActions';
import { CheckinConfirmationData, CheckinInitialData, NID, PatientData } from './types';
import { SymptomCheckerScreen } from '../../Views/SymptomCheckerScreen/SymptomCheckerScreen';

export const CheckInProcess = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const checkIn = useSelector((state: RootState) => state.checkIn);
	const healthCentreId = useSelector(getHealthCentreId);
	const checkinData: null | CheckinConfirmationData = useSelector(getPatientData);
	const dispatch = useDispatch();
	const IDLE_TIME = 1000 * 60 * 2;

	const onIdle = () => handleIdleState(checkIn);

	const initCheckin = (locale: Languages) => {
		const initialData: CheckinInitialData = { healthCentreId, locale };
		dispatch(initCheckinProcess(initialData));
	};

	const setPatientDetailsPage = () => {
		dispatch(checkInActions.setPatientDetailsScreenStep(''));
	};

	const setSymptomCheckerStartPage = () => {
		dispatch(checkInActions.setSymptomCheckerStart(checkinData));
	};

	const submitData = (patientData: PatientData) => dispatch(fetchRegistrationDetails(patientData));

	const submitHohData = (hohNID: NID) => dispatch(fetchInsuranceDetails(hohNID));

	const getErrorMessage = () => {
		if (checkIn.step === 'CHECKIN_ERROR_SCREEN') {
			if (checkIn.errors.status === 402) {
				return formatMessage(errorMessages.insuranceExpired);
			} else if (checkIn.errors.status === 400 || checkIn.errors.status === 404) {
				return formatMessage(errorMessages.insuranceNotFound);
			} else {
				return formatMessage(errorMessages.generalError);
			}
		}
	};

	const renderCheckInProcessContent = () => {
		switch (checkIn.step) {
			case 'PATIENT_REGISTRATION_DATA':
			case 'CHECKIN_PROCESS_FINALIZED':
			case 'LOADING': {
				return <Spinner />;
			}
			case 'LANGUAGE_SELECTION_SCREEN': {
				return <LanguagePage onSelectLanguage={initCheckin} />;
			}
			case 'CATCHMENT_AREA_SCREEN': {
				return <CatchmentAreaPage onAnswer={setPatientDetailsPage} />;
			}
			case 'IDENTITY_CARD_SCANNER_SCREEN': {
				return <IdentityCardScanner onSubmitData={submitData} />;
			}
			case 'PATIENT_DETAILS_SCREEN': {
				return <PatientDetails onSubmitData={submitData} errors={checkIn.errors} />;
			}
			case 'HOH_DETAILS_SCREEN': {
				return <HouseholdDetails onSubmitData={submitHohData} />;
			}
			case 'CHECKIN_ERROR_SCREEN': {
				return <ErrorScreen error={getErrorMessage()} onClick={handleRestartRegistration} />;
			}
			case 'CHECKIN_CONFIRMATION_SCREEN': {
				return <CheckinConfirmation {...{ checkinData }} onClick={setSymptomCheckerStartPage} />;
			}
			case 'SYMPTOM_CHECKER_START': {
				const patientNid = checkinData ? checkinData.patientNid : null;
				const patientId = checkinData ? checkinData.patientId : null;
				return <SymptomCheckerScreen {...{ patientId, patientNid }} onClick={handleRestartRegistration} />;
			}
			default: {
				assertExhaustive(checkIn);
			}
		}
	};

	useIdleTimer({ onIdle, timeout: IDLE_TIME });

	return (
		<>
			<TopBar />
			{renderCheckInProcessContent()}
		</>
	);
};
