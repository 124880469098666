import dayjs from 'dayjs';

import { AgeRange } from '../types/unions';

export const getAgeRange = (dateOfBirth: string): AgeRange => {
	const currentDateString = dayjs().format('YYYY-MM-DD');
	const age = dayjs(currentDateString).diff(dateOfBirth, 'year');
	if (age <= 25) return '16-25';
	if (age <= 45) return '26-45';
	return 'above-45';
};
