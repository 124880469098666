import { FallbackProps } from 'react-error-boundary';
import { ButtonSimple } from '@babylon/medkit';

export const HttpErrorFallback = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
	const handleResetApp = () => resetErrorBoundary();
	const { message } = error;

	return (
		<div>
			<pre>{message}</pre>
			<ButtonSimple onClick={handleResetApp}>Try again</ButtonSimple>
		</div>
	);
};
