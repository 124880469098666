import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { checkInActions } from '../../redux/CheckIn/checkIn.slice';
import { Container } from '../../components/Container';
import { PatientData } from '../../Processes/CheckInProcess/types';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { trimSpaces } from '../../utils/trimSpaces';

import messages from './messages';
import { IdentityCardScannerStyles } from './IdentityCardScannerStyles';
import cardHolder from './assets/cardHolder.jpg';
import { BarCodeScanner } from './BarcodeScanner';

type IdentityCardScannerProps = {
	onSubmitData?: (patientData: PatientData) => void;
};

export const IdentityCardScanner = ({ onSubmitData = () => null }: IdentityCardScannerProps): JSX.Element => {
	const { height, width } = useWindowDimensions();
	const [landscapeView, setLandscapeView] = useState(true);
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const extractAndSendNid = (barcode: string) => {
		onSubmitData({ patientNID: trimSpaces(barcode.slice(0, 21)) });
	};

	useEffect(() => {
		setLandscapeView(width > height);
	}, [width, height]);

	return (
		<>
			<Container>
				<IdentityCardScannerStyles.InstructionsContainer>
					<IdentityCardScannerStyles.StyledCardHolder src={cardHolder} />
					<IdentityCardScannerStyles.Instructions>
						<IdentityCardScannerStyles.InstructionsHeader variant="h3">
							{formatMessage(messages.callToAction)}
						</IdentityCardScannerStyles.InstructionsHeader>
						<IdentityCardScannerStyles.InstructionsList>
							<li>{formatMessage(messages.stepOne)}</li>
							<li>{formatMessage(messages.stepTwo)}</li>
							<li>{formatMessage(messages.stepThree)}</li>
						</IdentityCardScannerStyles.InstructionsList>
					</IdentityCardScannerStyles.Instructions>
				</IdentityCardScannerStyles.InstructionsContainer>
				<IdentityCardScannerStyles.Consent variant="h5">
					{formatMessage(messages.consent)}
				</IdentityCardScannerStyles.Consent>
				<IdentityCardScannerStyles.ScannerContainer>
					<IdentityCardScannerStyles.StyledCardTarget landscape={landscapeView} />
					<BarCodeScanner
						onUpdate={(err, result) => {
							if (result) extractAndSendNid(result.getText());
						}}
					/>
				</IdentityCardScannerStyles.ScannerContainer>
				<IdentityCardScannerStyles.FallbackContainer>
					<IdentityCardScannerStyles.StyledLinkButton
						onClick={() => dispatch(checkInActions.setPatientDetailsScreenStep(''))}
					>
						{formatMessage(messages.fallbackOption)}
					</IdentityCardScannerStyles.StyledLinkButton>
				</IdentityCardScannerStyles.FallbackContainer>
			</Container>
		</>
	);
};
