import ReactDOM from 'react-dom';
import App from './App';
import { worker } from './api/mockApi/worker';

const REACT_APP_NAME = process.env.REACT_APP_NAME || 'UNKNOWN';
const REACT_APP_VERSION = process.env.REACT_APP_VERSION || 'UNKNOWN';
const MOCK_API_REQUESTS = process.env.REACT_APP_MOCK_API_REQUESTS === 'TRUE';

console.log(`${REACT_APP_NAME} version ${REACT_APP_VERSION}`);

if (MOCK_API_REQUESTS || process.env.NODE_ENV === 'test') {
	worker.start();
}

ReactDOM.render(<App />, document.getElementById('root'));
