import { CheckinConfirmationData } from '../../Processes/CheckInProcess/types';
import { PrintTicket } from '../PrintTicket/PrintTicket';
import generalMessages from '../../Views/messages';

type CheckinConfirmationProps = {
	onClick?: () => void;
	checkinData: null | CheckinConfirmationData;
};

export const CheckinConfirmation = ({ onClick, checkinData }: CheckinConfirmationProps): JSX.Element =>
	PrintTicket({ nextButtonLabel: generalMessages.next, onClick, data: checkinData });
