import { defineMessages } from 'react-intl';

export default defineMessages({
	results: {
		id: 'SymptomCheckerCompletedPage.results',
		defaultMessage: 'Results',
	},
	confirmation: {
		id: 'SymptomCheckerCompletedPage.confirmation',
		defaultMessage: 'Symptom Checker completed',
	},
	instructionTwo: {
		id: 'SymptomCheckerCompletedPage.instructionTwo',
		defaultMessage: 'Use it at the next counter.',
	},
});
