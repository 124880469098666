import { useSelector } from 'react-redux';
import {
	NID,
	TriageOutcomeConversationHealthCentreIdFallback,
	TriageOutcomeConversationPatientNidFallback,
} from '../../Processes/CheckInProcess/types';
import { selectLocale } from '../../redux/Locale/locale.slice';
import { SymptomCheckerCompletedPath } from '../../Router';
import { Languages, SymptomCheckerLanguages, SymptomCheckerCompletedStatus } from '../../types/enums';
import createUrl from '../../utils/createUrl';
import { encryptString } from '../../utils/encryption';
import MockedSymptomChecker from './MockedSymptomChecker';

const MOCK_API_REQUESTS = process.env.REACT_APP_MOCK_API_REQUESTS === 'TRUE';
type Props = {
	healthCentreId: string | null;
	patientId: number | null;
	patientNid: NID | null;
};

const convertLanguageToSymptomCheckerLocale = (locale: string) => {
	switch (locale) {
		case Languages.EN:
			return SymptomCheckerLanguages.EN;
		case Languages.RW:
			return SymptomCheckerLanguages.RW;
		default:
			return '';
	}
};

export default function SymptomCheckerStart({ healthCentreId, patientId, patientNid }: Props): JSX.Element {
	const queryParams = new URLSearchParams(window.location.search);
	const locale: string = queryParams.get('locale') || useSelector(selectLocale);
	const encryptedHealthCentreId = encryptString(healthCentreId || TriageOutcomeConversationHealthCentreIdFallback);
	const encryptedNid = encryptString(patientNid || TriageOutcomeConversationPatientNidFallback);
	const currentUrl = window.location.protocol + '//' + window.location.host + SymptomCheckerCompletedPath;
	const redirectUrl = (status: SymptomCheckerCompletedStatus): string =>
		createUrl(currentUrl, { encryptedHealthCentreId, encryptedNid, status, locale });
	const params = {
		app_locale: convertLanguageToSymptomCheckerLocale(locale),
		source: 'hc_app',
		patientId: patientId ? patientId.toString() : '',
		triage_exited_url: redirectUrl(SymptomCheckerCompletedStatus.EXITED),
		triage_completed_url: redirectUrl(SymptomCheckerCompletedStatus.COMPLETED),
	};
	const url = createUrl(`${process.env.REACT_APP_RWANDA_WEB_DOMAIN}`, params);

	if (MOCK_API_REQUESTS) {
		return <MockedSymptomChecker />;
	} else {
		window.location.replace(url);
		return <></>;
	}
}
