import { Dispatch } from 'redux';
import { RootState } from '../store';
import { timeLogActions } from './timeLog.slice';
import { TimeLogEvent } from './TimeLogEvent';
import { postTimeLog } from '../../api/apiCalls/postTimeLog';
import { TimeLogState } from '../../Processes/CheckInProcess/types';
import { HCAuthorizationProcessState } from '../../Processes/HCLoginProcess/types';

const postTimeLogEventsAction = { type: 'timeLog/postTimeLog' };

const postEvents = (timeLog: TimeLogState, healthCentre: HCAuthorizationProcessState, dispatch: Dispatch) => {
	if (healthCentre.step === 'HC_LOGIN_SUCCESSFUL') {
		dispatch(postTimeLogEventsAction);
		postTimeLog(timeLog, healthCentre.token, healthCentre.healthCentreId, healthCentre.healthCentreName);
	}
	dispatch(timeLogActions.clearEvents());
};

const cacheEvent = (eventName: string, eventValue?: string | null) => (dispatch: Dispatch) => {
	dispatch(timeLogActions.pushEvent(TimeLogEvent.construct(eventName, eventValue)));
};

const logEvent = (eventName: string, eventValue?: string | null) => (dispatch: Dispatch, getState: () => RootState) => {
	dispatch(timeLogActions.pushEvent(TimeLogEvent.construct(eventName, eventValue)));
	const { timeLog, healthCentre } = getState();
	postEvents(timeLog, healthCentre, dispatch);
};

const initSession =
	(healthCentreId: string | null, appName: string, appVersion: string) =>
	(dispatch: Dispatch, getState: () => RootState) => {
		dispatch(timeLogActions.initSession());
		dispatch(timeLogActions.pushEvent(TimeLogEvent.construct('initSession')));
		if (healthCentreId) {
			dispatch(timeLogActions.pushEvent(TimeLogEvent.construct('setHealthCentreId', healthCentreId)));
			dispatch(timeLogActions.pushEvent(TimeLogEvent.construct('setAppName', appName)));
			dispatch(timeLogActions.pushEvent(TimeLogEvent.construct('setAppVersion', appVersion)));
		}
		const { timeLog, healthCentre } = getState();
		postEvents(timeLog, healthCentre, dispatch);
	};

export { logEvent, cacheEvent, initSession };
