import { createSlice } from '@reduxjs/toolkit';

import { HCAuthorizationProcessState } from '../../Processes/HCLoginProcess/types';
import { autoRefreshOnCookieExpiration, getSessionIsResumable, getSessionStorageData } from '../../Processes/session';

import { staffLogin } from './staffLogin';

export const BASENAME = 'healthCentre';

const getInitialState = () => {
	const sessionIsResumable = getSessionIsResumable();
	const sessionData = getSessionStorageData();
	if (sessionIsResumable && sessionData) {
		const { token, expires, healthCentreId, healthCentreName } = sessionData;
		autoRefreshOnCookieExpiration(expires);
		return { step: 'HC_LOGIN_SUCCESSFUL', token, healthCentreId, healthCentreName } as HCAuthorizationProcessState;
	}
	return { step: 'HC_LOGIN_FORM' } as HCAuthorizationProcessState;
};

const healthCentreSlice = createSlice({
	name: BASENAME,
	initialState: getInitialState(),
	reducers: {
		setLoginForm: (_) => ({ step: 'HC_LOGIN_FORM' }),
	},
	extraReducers: (builder) => {
		builder.addCase(staffLogin.pending, (state) => {
			state.step = 'LOADING';
		});
		builder.addCase(staffLogin.fulfilled, (state, action) => ({
			step: 'HC_LOGIN_SUCCESSFUL',
			token: action.payload.token,
			healthCentreId: action.payload.healthCentreId,
			healthCentreName: action.payload.healthCentreName,
		}));
		builder.addCase(staffLogin.rejected, (state, action) => {
			if (state.step === 'LOADING' && action.payload) {
				return {
					step: 'HC_LOGIN_FORM',
					errors: { ...action.payload },
				};
			}
		});
	},
});

export const { reducer, actions: healthCentreAction } = healthCentreSlice;
