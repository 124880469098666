import { Dispatch } from 'redux';
import { store } from '../../redux/store';
import { assertExhaustive } from '../assertExhaustive';
import { setLocale } from '../../redux/Locale/locale.slice';
import { initSession, logEvent } from '../../redux/TimeLog/eventsService';
import { checkInActions } from '../../redux/CheckIn/checkIn.slice';
import { catchmentAreaActions } from '../../redux/CatchmentArea/catchmentArea.slice';
import { CheckinInitialData, CheckinProcessState } from './types';

export const initCheckinProcess =
	(initialData: CheckinInitialData) =>
	(dispatch: Dispatch): void => {
		const { healthCentreId, locale } = initialData;
		dispatch(setLocale(locale));
		const REACT_APP_NAME = process.env.REACT_APP_NAME || 'UNKNOWN';
		const REACT_APP_VERSION = process.env.REACT_APP_VERSION || 'UNKNOWN';
		store.dispatch(initSession(healthCentreId, REACT_APP_NAME, REACT_APP_VERSION));
		dispatch(checkInActions.setCatchmentAreaScreenStep());
	};

export const restartRegistration =
	() =>
	(dispatch: Dispatch): void => {
		window.location.href = '/';
	};

export const abandonRegistration =
	() =>
	(dispatch: Dispatch): void => {
		store.dispatch(logEvent('abandonSession'));
		dispatch(catchmentAreaActions.setDefault());
		dispatch(checkInActions.restartRegistrationProcess());
	};

export const handleRestartRegistration = (): void => {
	store.dispatch(restartRegistration());
};

export const handleIdleState = (checkIn: CheckinProcessState): void => {
	switch (checkIn.step) {
		case 'PATIENT_REGISTRATION_DATA':
		case 'CHECKIN_PROCESS_FINALIZED':
		case 'LOADING':
		case 'LANGUAGE_SELECTION_SCREEN': {
			break;
		}
		case 'CATCHMENT_AREA_SCREEN':
		case 'IDENTITY_CARD_SCANNER_SCREEN':
		case 'PATIENT_DETAILS_SCREEN':
		case 'HOH_DETAILS_SCREEN': {
			store.dispatch(abandonRegistration());
			break;
		}
		case 'CHECKIN_ERROR_SCREEN':
		case 'CHECKIN_CONFIRMATION_SCREEN':
		case 'SYMPTOM_CHECKER_START': {
			handleRestartRegistration();
			break;
		}
		default: {
			assertExhaustive(checkIn);
		}
	}
};
