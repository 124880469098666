import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { PostCheckPolicyResponse } from '../../types/contracts';
import { AppError, NID, PatientCheckinData, RegistrationData, ValidPolicy } from '../../Processes/CheckInProcess/types';
import { postInsuranceCheck } from '../../api/apiCalls/postInsuranceCheck';
import { mockPolicyGeneralError } from '../../api/mockApi/mocks';
import { extractNid } from '../../utils/extractNid';
import { formatDate } from '../../utils/formatDate';
import { getAppError, isAxiosError } from '../axiosError';
import { logEvent, cacheEvent } from '../../redux/TimeLog/eventsService';
import { RootState } from '../store';
import { checkInActions } from './checkIn.slice';

const getInsuranceResponse = async (
	registrationDetails: RegistrationData,
	hohNid: string,
	token: string
): Promise<AxiosResponse<PostCheckPolicyResponse>> => {
	return await postInsuranceCheck(registrationDetails, hohNid, token);
};

const getCheckinData = (patientId: number, result: AxiosResponse<ValidPolicy>): PatientCheckinData => {
	const currentDate = new Date();
	const dateOfToday = formatDate(
		currentDate.getDate().toString(),
		(currentDate.getMonth() + 1).toString(),
		currentDate.getFullYear().toString()
	);
	const policyData = result.data;
	return {
		dateOfToday,
		patientId,
		name: `${policyData.insurance.beneficiary.resource.name[0].text}`,
		patientNid: extractNid(policyData.insurance.beneficiary.identifier.system),
		insuranceProvider: policyData.insurance.network,
		hohNid: extractNid(policyData.insurance.policyHolder.identifier.system),
		hohName: `${policyData.insurance.policyHolder.resource.name[0].text}`,
		ubudeheCategory: `${policyData.insurance.class?.value || ''}`,
	};
};

export const fetchInsuranceDetails = createAsyncThunk<
	PatientCheckinData,
	NID,
	{ rejectValue: AppError; state: RootState }
>(`registration/fetchInsuranceDetails`, async (hohNid: NID, thunkApi) => {
	const { checkIn, healthCentre } = thunkApi.getState();
	try {
		if (
			healthCentre.step === 'HC_LOGIN_SUCCESSFUL' &&
			(checkIn.step === 'PATIENT_REGISTRATION_DATA' || checkIn.step === 'HOH_DETAILS_SCREEN')
		) {
			thunkApi.dispatch(checkInActions.setLoadingStep());
			const insuranceResponse = await getInsuranceResponse(checkIn.patient, hohNid, healthCentre.token);
			thunkApi.dispatch(logEvent('setInsuranceResult', `${insuranceResponse.status}`));
			const patientId = parseInt(checkIn.patient.patientUrn.split(':').slice(-1)[0]);
			if (!patientId) {
				throw new Error(`Could not parse patientId from checkIn.patient.patientUrn: ${checkIn.patient.patientUrn}`);
			}
			return getCheckinData(patientId, insuranceResponse);
		}
	} catch (error) {
		if (isAxiosError(error)) {
			const appError = getAppError(error);
			if (checkIn.step === 'PATIENT_REGISTRATION_DATA') {
				if (appError.status === 404) {
					thunkApi.dispatch(cacheEvent('setInsuranceResult', `${appError.status}`));
					thunkApi.dispatch(checkInActions.setHohDetailsScreenStep(checkIn.patient));
				} else {
					thunkApi.dispatch(logEvent('setInsuranceResult', `${appError.status}`));
					thunkApi.dispatch(checkInActions.setCheckinErrorScreenStep(appError));
				}
			}
			if (checkIn.step === 'HOH_DETAILS_SCREEN') {
				thunkApi.dispatch(logEvent('setInsuranceResult', `${appError.status}`));
				thunkApi.dispatch(checkInActions.setCheckinErrorScreenStep(appError));
			}
			return thunkApi.rejectWithValue(appError);
		}
	}
	return thunkApi.rejectWithValue(mockPolicyGeneralError);
});
