import { defineMessages } from 'react-intl';

export default defineMessages({
	callToAction: {
		id: 'PatientDetails.callToAction',
		defaultMessage: 'Please enter your details as shown on your ID card',
	},
	birthdayLabel: {
		id: 'PatientDetails.birthdayLabel',
		defaultMessage: 'Date of birth on ID card',
	},
	dayLabel: {
		id: 'PatientDetails.dayLabel',
		defaultMessage: 'Day',
	},
	monthLabel: {
		id: 'PatientDetails.monthLabel',
		defaultMessage: 'Month',
	},
	yearLabel: {
		id: 'PatientDetails.yearLabel',
		defaultMessage: 'Year',
	},
	nidLabel: {
		id: 'PatientDetails.nidLabel',
		defaultMessage: 'National ID number on ID card',
	},
	consent: {
		id: 'PatientDetails.consent',
		defaultMessage:
			'I understand that any information entered here will be used exclusively to check the validity of my insurance. This data will be stored by Babyl and can be deleted upon request.',
	},
	learnMore: {
		id: 'PatientDetails.learnMore',
		defaultMessage: 'Learn more',
	},
	noMatch: {
		id: 'PatientDetails.noMatch',
		defaultMessage:
			'Sorry, no record matching that NID and date of birth could be found. Please check if both are correct and try again',
	},
	missingDateOfBirth: {
		id: 'PatientDetails.missingDateOfBirth',
		defaultMessage: 'Missing date of birth',
	},
	missingNid: {
		id: 'PatientDetails.missingNid',
		defaultMessage: 'Missing ID number',
	},
	missingDoBandNid: {
		id: 'PatientDetails.missingDoBandNid',
		defaultMessage: 'Missing date of birth and ID number',
	},
	termsTitle: {
		id: 'PatientDetails.termsTitle',
		defaultMessage: 'Terms & Conditions',
	},
	termsSubtitle: {
		id: 'PatientDetails.termsSubtitle',
		defaultMessage: 'Our terms of Use',
	},
	termsContent: {
		id: 'PatientDetails.termsContent',
		defaultMessage:
			'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum itaque necessitatibus rem rerum totam? Cum distinctio eligendi esse ex, fugiat hic illum quos sapiente veritatis voluptas. Animi dicta dolores porro. Consequuntur doloremque molestias nemo nulla placeat quisquam rem repellendus reprehenderit repudiandae veritatis. Aliquam amet, cupiditate doloremque eaque facilis id iusto laborum minima nihil praesentium quisquam quos sapiente, sit, tenetur vel! A assumenda commodi officiis! A assumenda delectus dolore eligendi esse et ex facere fugit illum iusto laboriosam magnam necessitatibus nemo nisi, quaerat quia repellendus sapiente sint temporibus, unde velit voluptate.',
	},
});
