import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@babylon/medkit';
import ErrorMessages from '@babylon/medkit/lib/Forms/ErrorMessages';

import { Container } from '../../components/Container';
import { StaffCredentials } from '../../Processes/HCLoginProcess/types';
import { LoginError } from '../../Processes/CheckInProcess/types';
import logo from '../../assets/babyl-logo.png';
import { staffLogin } from '../../redux/HealthCentre/staffLogin';
import engLocale from '../../translations/en.json';
import rwLocale from '../../translations/rw.json';

import { HCLoginPageStyles } from './HCLoginPage.styles';

type HCLoginPageInputTypes = {
	email: string;
	password: string;
};

type HCLoginPageProps = {
	errors?: LoginError;
};

const initialStaffCredentials: StaffCredentials = { email: '', password: '' };

const hcLoginPageInputNames: Readonly<Record<keyof HCLoginPageInputTypes, string>> = {
	email: 'email',
	password: 'password',
};

export const HCLoginPage = ({ errors }: HCLoginPageProps): JSX.Element => {
	const dispatch = useDispatch();
	const [staffCredentials, setStaffCredentials] = useState(initialStaffCredentials);
	const [credentialsError, setCredentialsError] = useState<string[]>();
	const [emailError, setEmailError] = useState<string[]>();

	const loginStaffUser = (staffCredentials: StaffCredentials) => {
		dispatch(staffLogin(staffCredentials));
	};
	const validateEmail = () => {
		const emailPattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z_.]{2,30}[.][a-z]{2,5}/g;
		emailPattern.test(staffCredentials.email)
			? setEmailError(undefined)
			: setEmailError([engLocale['LoginPage.emailError'], rwLocale['LoginPage.emailError']]);
	};
	const handleEmailChange = (email: string) => {
		setStaffCredentials({ ...staffCredentials, email });
	};
	const handlePasswordChange = (password: string) => {
		setStaffCredentials({ ...staffCredentials, password });
	};
	const handleCredentialsError = () => {
		setCredentialsError([engLocale['LoginPage.credentialsError'], rwLocale['LoginPage.credentialsError']]);
	};
	const handleLogin = () => {
		staffCredentials.email && staffCredentials.password && !emailError
			? loginStaffUser(staffCredentials)
			: handleCredentialsError();
	};

	useEffect(() => {
		if (errors) {
			handleCredentialsError();
		}
	}, [errors]);

	return (
		<>
			<HCLoginPageStyles.VersionNumber>v {process.env.REACT_APP_VERSION}</HCLoginPageStyles.VersionNumber>
			<Container>
				<HCLoginPageStyles.Container>
					<HCLoginPageStyles.Logo src={logo} alt="" />
					<HCLoginPageStyles.StyledInput
						type="email"
						name={hcLoginPageInputNames.email}
						label={`${engLocale['LoginPage.emailLabel']} / ${rwLocale['LoginPage.emailLabel']}`}
						onChange={(e) => handleEmailChange(e.currentTarget.value)}
						onBlur={validateEmail}
					/>
					<ErrorMessages fieldName={hcLoginPageInputNames.email} formErrors={emailError} />
					<Input
						type="password"
						name={hcLoginPageInputNames.password}
						label={`${engLocale['LoginPage.passwordLabel']} / ${rwLocale['LoginPage.passwordLabel']}`}
						onChange={(e) => handlePasswordChange(e.currentTarget.value)}
					/>
					<ErrorMessages formErrors={credentialsError} />
					<HCLoginPageStyles.Button onClick={() => handleLogin()}>
						{`${engLocale['LoginPage.loginButton']} / ${rwLocale['LoginPage.loginButton']}`}
					</HCLoginPageStyles.Button>
				</HCLoginPageStyles.Container>
			</Container>
		</>
	);
};
