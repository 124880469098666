export enum Languages {
	EN = 'en-RW',
	RW = 'rw-RW',
}

export enum SymptomCheckerLanguages {
	EN = 'en_GB',
	RW = 'rw_RW',
}

export enum SymptomCheckerCompletedStatus {
	EXITED = 'exited',
	COMPLETED = 'completed',
}
