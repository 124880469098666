import { defineMessages } from 'react-intl';

export default defineMessages({
	start: {
		id: 'General.start',
		defaultMessage: 'Start',
	},
	back: {
		id: 'General.back',
		defaultMessage: 'Back',
	},
	finish: {
		id: 'General.finish',
		defaultMessage: 'Finish',
	},
	next: {
		id: 'General.next',
		defaultMessage: 'Next',
	},
	continue: {
		id: 'General.continue',
		defaultMessage: 'Continue',
	},
	close: {
		id: 'General.close',
		defaultMessage: 'Close',
	},
	yes: {
		id: 'General.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'General.no',
		defaultMessage: 'No',
	},
	householdQuestion: {
		id: 'General.householdQuestion',
		defaultMessage: 'Are you the head of household?',
	},
	hohNidLabel: {
		id: 'General.hohNidLabel',
		defaultMessage: 'National ID number for head of household',
	},
	printerMalfunction: {
		id: 'General.printerMalfunction',
		defaultMessage: 'Sorry, something is wrong with the printer',
	},
	printerMalfunctionInstructions: {
		id: 'General.printerMalfunctionInstructions',
		defaultMessage: 'Please speak to staff member for assistance',
	},
	ok: {
		id: 'General.ok',
		defaultMessage: 'Ok',
	},
	english: {
		id: 'General.english',
		defaultMessage: 'English',
	},
	kinyarwandan: {
		id: 'General.kinyarwandan',
		defaultMessage: 'Kinyarwanda',
	},
	topbarTitle: {
		id: 'General.topbarTitle',
		defaultMessage: 'Insurance check',
	},
	catchmentAreaQuestion: {
		id: 'General.catchmentAreaQuestion',
		defaultMessage: 'In Catchment Area',
	},
	symptomCheckerQuestion: {
		id: 'General.symptomCheckerQuestion',
		defaultMessage: 'Would you like to use our Symptom Checker?',
	},
	symptomCheckerExitedByUser: {
		id: 'General.symptomCheckerExitedByUser',
		defaultMessage: 'Symptom Checker exited',
	},
	symptomCheckerOutcomeUnknown: {
		id: 'General.symptomCheckerOutcomeUnknown',
		defaultMessage: 'Triage Outcome Unknown',
	},
	symptomCheckerLabel: {
		id: 'General.symptomCheckerLabel',
		defaultMessage: 'Symptom Checker',
	},
	symptomCheckerUnexpectedError: {
		id: 'General.symptomCheckerUnexpectedError',
		defaultMessage: 'An Unexpected Error Occurred',
	},
});
