import { Languages } from '../../types/enums';

export type CheckinProcessState =
	| { step: 'LOADING' }
	| { step: 'LANGUAGE_SELECTION_SCREEN' }
	| { step: 'CATCHMENT_AREA_SCREEN' }
	| { step: 'PATIENT_DETAILS_SCREEN'; errors?: AppError }
	| { step: 'IDENTITY_CARD_SCANNER_SCREEN' }
	| { step: 'PATIENT_REGISTRATION_DATA'; patient: RegistrationData }
	| { step: 'HOH_DETAILS_SCREEN'; patient: RegistrationData }
	| { step: 'CHECKIN_ERROR_SCREEN'; errors: AppError }
	| { step: 'CHECKIN_CONFIRMATION_SCREEN'; policy: PatientCheckinData }
	| { step: 'SYMPTOM_CHECKER_START'; policy: PatientCheckinData | null }
	| { step: 'CHECKIN_PROCESS_FINALIZED' };

export type CheckinInitialData = {
	healthCentreId: string | null;
	locale: Languages;
};

export type CatchmentAreaState = {
	isFromCatchmentArea: boolean;
};

export type CheckinConfirmationData = PatientCheckinData & CatchmentAreaState;

export type TriageOutcomeConversation = {
	id: string;
	healthCentreId: string;
	patientNid: NID;
	sex: string;
	age: number;
	completedAt: string;
	initialInput: string;
	triageOutcome: string;
	positiveSymptoms: string[];
};

export const TriageOutcomeConversationPatientNidFallback = 'Unknown';
export const TriageOutcomeConversationHealthCentreIdFallback = 'Unknown';

export type TriageOutcomeData = {
	conversation: TriageOutcomeConversation;
};

export type Status = {
	status: string | number;
};

export type TimelogEvent = {
	event_name: string;
	event_value: string;
};

export type Timestamp = TimelogEvent & {
	timestamp: string;
};

export type TimeLogState = {
	session?: string;
	events: Timestamp[];
};

export type PatientData = {
	patientNID: NID;
};

export type RegistrationData = {
	dateOfBirth: DateOfBirth;
	firstName: string;
	identifier: UrnNid;
	identityUrn: string;
	lastName: string;
	patientUrn: PatientUrn;
};

export type AppError = {
	error: string;
	message: string;
	status: number | string;
	timestamp?: string;
};

export type LoginError = {
	error_codes: string[];
	error_details: {
		base: [{ error: string }];
	};
	errors: { base: string[] };
};

export type PatientCheckinData = {
	dateOfToday: string;
	patientId: number;
	name: string;
	patientNid: string;
	insuranceProvider: string;
	hohNid: string;
	hohName: string;
	ubudeheCategory: string;
};

export type UrnNid = string;
export type NID = string;

export type DateOfBirth = string;

type PatientUrn = string;

type SystemIdentifier = {
	system: string;
};

type PolicyName = {
	use: string;
	text: string;
};

type Person = {
	identifier: SystemIdentifier;
	resource: Resource;
};

type Resource = {
	resourceType: string;
	name: PolicyName[];
	gender: string;
};

type Period = {
	start: string;
	end: string;
};

type Payor = {
	identifier: SystemIdentifier;
	resource: Pick<Resource, 'resourceType'>;
};

type UbudeheCategory = {
	type: 'group';
	value: string;
};

export type ValidPolicy = {
	insurance: {
		identifier: SystemIdentifier[];
		status: string;
		policyHolder: Person;
		subscriber: Person;
		beneficiary: Person;
		period: Period;
		payor: Payor[];
		network: 'MUTUELLE';
		class?: UbudeheCategory;
	};
};

export type ExtractedTranslations = {
	[key: string]: string;
};

export type SymptomCheckerConversationResponse = {
	conversationId: string;
	conversationAttachment: { body: string };
	messages: [{ payload: string }];
};

export type SymptomCheckerConversationMessage = {
	sender: string;
	value: { text: { member_translation: string } };
};

export type SymptomCheckerConversationAttachment = {
	case: { age: number; completed_at: string; sex: string };
	positive_symptoms: [{ layman_name: string }];
	status: 'succeeded' | 'failed';
	failure_reason: { message: string };
	triage: { title: string };
};
