import { Text } from '@babylon/medkit';
import styled from 'styled-components';

const Wrapper = styled.div`
	h2 {
		margin: 50px 0;
	}
	h3 {
		margin: 35px 0;
	}
	p {
		margin: 20px 0;
		padding: 0 20px;
	}
`;

export const TermsAndConditions = (): JSX.Element => {
	return (
		<Wrapper>
			<Text variant="h3">Our Mission</Text>
			<Text variant="body">
				At Babyl, our mission is to make healthcare better and simpler for everyone. Above all, we’re committed to
				providing you with the highest quality care. Because we’re a Rwanda regulated healthcare company, the terms and
				conditions we need to include are quite long and use formal language.
			</Text>
			<Text variant="body">
				By using this App/USSD service you are deemed to understand and agree to these terms and conditions, including
				those relating to our liability at clause 7 and our privacy policy.
			</Text>
			<Text variant="body">
				The way we work is simple: we’re here to serve you and will always do the right thing to ensure you have the
				best possible experience. We hold ourselves to the highest legal and ethical standards and we will never
				jeopardise your trust in us by failing to live up to these ideals.
			</Text>
			<Text variant="body">
				We value hearing from you so if you have any questions or ideas, don’t hesitate to get in touch by calling 8111,
				or emailing support@babyl.rw.
			</Text>
			<Text variant="h2">Babylon Rwanda Limited - Consumer Terms and Conditions</Text>
			<Text variant="body">
				We may amend these from time to time as set out in clause 3. Please check to ensure you understand the terms
				that will apply at the time you enter into any transaction with the Company. These Terms were most recently
				updated on [insert date of publication] 2016.
			</Text>
			<Text variant="h3">1. Definitions</Text>
			<Text variant="body">
				1.1 When the following words with capital letters are used in these Terms, this is what they will mean:
			</Text>
			<Text variant="body">
				<ol>
					<li>
						“Advice” means the advice or other services provided by a Practitioner or the Company to a User through the
						App or otherwise facilitated by the Company.
					</li>
					<li>
						Affiliates: those persons providing Services or Products through the Company or its App or ordered through
						the Company or its App, including suppliers and Practitioners and any subcontractors to the Company.
					</li>
					<li>
						USSD Service: means the Babyl mobile service accessed through the code as specified by the Company and
						available with Tigo and MTN networks.
					</li>
					<li>
						Company: Babylon Rwanda Limited registered in Rwanda with company number 105126570, registered office at
						Floor 6, Ecobank, Plot 314, Avenue de la Paix, Kigali, Rwanda.
					</li>
					<li>Event Outside Our Control: is defined in clause 8.2.</li>
					<li>
						Materials: means any materials, documents, information, intellectual property or other assets belonging to
						the Company and/or any Affiliate.
					</li>
					<li>
						Practitioner: the medical health practitioner carrying out or providing the Services to include but not
						limited to a registered general physician (“GP”), registered nurse (“Nurse”) or registered medical
						specialist clinician or consultant (“Specialist”) registered, as applicable, with the relevant body in
						Rwanda.
					</li>
					<li>
						Product: any physical product ordered by you through the Company’s App or which the Company provides to you.
					</li>
					<li>
						Services: any services that the Company provides to you or which you order or access through the Company’s
						App/ USSD Service.
					</li>
					<li>Terms: the terms and conditions set out in this document including the Schedules.</li>
					<li>
						User: means a customer of the Company who makes use of the Services and/or orders or purchases Products via
						the App/ USSD service.
					</li>
					<li>
						In writing or written: shall include in relation to any communication from the Company or its Affiliates,
						any email, letters, on-screen alert, on-screen notification, prompt, field or other communication of
						information to you from the Company or its Affiliates and in relation to any communication from you to the
						Company shall include any email address provided to you by the Company for such communication.
					</li>
				</ol>
			</Text>
			<Text variant="h3">2. OUR CONTRACT WITH YOU</Text>
			<Text variant="body">
				2.1 The Services and Products ordered through the App/ USSD Service may be provided to you by the Company or by
				a third party. These are the terms and conditions on which the Company supplies Services, Products or otherwise
				contracts with you. If a third party provides a Service or Product through the App/ USSD Service, the third
				party may apply its terms and conditions to the Service or Product provided by it. Please ensure that you
				carefully read these Terms and the terms and conditions of any third party which provides a Service or Product
				through the App/ USSD Service.
			</Text>
			<Text variant="body">2.2 The Schedules to these Terms are part of the contract.</Text>
			<Text variant="body">
				2.3 The Company does not contract otherwise than on these Terms unless explicitly agreed in writing. If you do
				not agree with these Terms, you should not use the Company’s App/ USSD Service or otherwise order or use any
				Product or Services from the Company.
			</Text>
			<Text variant="h3">3. CHANGES TO TERMS</Text>
			<Text variant="body">
				3.1 The Company may revise these Terms from time to time in the following circumstances:
			</Text>
			<Text variant="body">
				<ol>
					<li>Changes in how the Company accepts payment from you;</li>
					<li>Changes in relevant laws and regulatory requirements; and</li>
					<li>
						Where the Company in its discretion deems a revision necessary or desirable and notifies you of such a
						revision.
					</li>
				</ol>
			</Text>
			<Text variant="body">
				3.2 If the Company makes a revision to these Terms under clause 3.1, the Company will notify you through the
				most appropriate means and you will have a right to cancel the contract in accordance with clause 9.3 (c). The
				then current Terms will apply to any transaction or use and it is your responsibility to ensure that you are
				content to contract on the then current Terms at the point of any transaction or use.
			</Text>
			<Text variant="h3">4. PROVIDING SERVICES</Text>
			<Text variant="body">
				4.1 The Company will not be responsible for any delays or lack of performance by it or any of its Affiliates due
				to an Event Outside Our Control. See clause 8.3 for the Company’s responsibilities when an Event Outside Our
				Control happens.
			</Text>
			<Text variant="body">
				4.2 The Company may have to suspend the Services if the Company has to deal with technical problems, or to make
				improvements to the Services. The Company will use reasonable efforts to minimise such times of suspension but
				such suspension shall not affect your obligation to pay any subscription or other payment due, provided that
				such period(s) of suspension do not exceed 30 days in aggregate in any year period.
			</Text>
			<Text variant="body">
				4.3 If you do not pay the Company for the Services when you are supposed to as set out in these Terms the
				Company may suspend the Services with immediate effect until you have paid the Company the outstanding amounts.
			</Text>
			<Text variant="h3">5. IF THERE IS A PROBLEM WITH THE SERVICES</Text>
			<Text variant="body">5.1 In the unlikely event that there is any defect with the Services:</Text>
			<Text variant="body">
				<ol>
					<li>Please contact and inform the Company as soon as reasonably possible;</li>
					<li>
						Please give the Company a reasonable opportunity to repair or fix any defect and/or to contact the relevant
						Practitioner and request that they carry out the required repair or fix the defect; and
					</li>
					<li>
						The Company will use reasonable efforts to repair or fix the defect as soon as reasonably practicable.
					</li>
				</ol>
			</Text>
			<Text variant="body">
				You will not have to pay for the Company or Practitioner to repair or fix a defect with the Services under this
				clause 5.1.
			</Text>
			<Text variant="body">
				5.2 As a consumer you have legal rights in relation to Services not carried out with reasonable skill and care,
				or if the materials the Company or Practitioner use are faulty or not as described.
			</Text>
			<Text variant="body">
				5.3 The Company values your feedback and, following a consultation on the App, provides you with the opportunity
				to rate your consultation and to provide additional comments. If you have any complaints, please do not hesitate
				to get in touch by telephoning the Customer Care team on 8111, or by e-mailing the Company at
				support@Babylrwanda.com.
			</Text>
			<Text variant="h3">6. PRICE AND PAYMENT</Text>
			<Text variant="body">
				6.1 The price of the Services or Products will be set out in the Company’s App/ USSD Service or in the Schedules
				to these Terms at the time of agreement by the Company to provide the Services and or Products. The Company’s
				prices may change at any time, but price changes will not affect any Services or Products which you have already
				ordered.
			</Text>
			<Text variant="body">
				6.2 These prices exclude: (i) VAT, where applicable, and unless otherwise stated; and: (ii) reasonable credit
				card and other processing fees, where these are incurred by the Company.
			</Text>
			<Text variant="body">
				6.3 Subject to clause 6.6, the Company will typically charge or invoice you for the Services or Products at the
				time you order them. Any amounts charged or invoiced are due and payable upon the invoice being issued unless
				otherwise specified in these Terms or the App.
			</Text>
			<Text variant="body">
				6.4 You can pay for Services or Products using a credit card, debit card or mobile money.
			</Text>
			<Text variant="body">
				6.5 Payment for the Services and/or Products and all applicable delivery charges are to be made in advance.
			</Text>
			<Text variant="body">
				6.6 When you book a consultation with a Practitioner, request a medical test or fulfilment of a prescription, or
				request other Products or Services from time to time, we may: (i) charge you in full for that Service or
				Product, or (ii) invoice your insurer, and in the event there is a shortfall in your insurer’s contribution
				relative to the price of the Product or Service, later invoice you for that shortfall.
			</Text>
			<Text variant="body">
				6.7 The Company will use reasonable endeavours to process select insurance claims directly with approved
				insurers on your behalf, providing you have provided correct and sufficient insurance details (including,
				without limitation, authorisation details for the relevant treatment), the relevant treatment is likely to be a
				treatment covered by the insurance company, you have consented or requested such processing in the App/ USSD
				Service and the insurance company is one which is on an approved list as having agreed to such interaction with
				the Company. By providing your insurer’s information through the App/ USSD Service you will be deemed to have
				consented to the above processing. It is your responsibility to verify with your insurer that the condition to
				be treated is covered by your insurance, and to obtain a pre-authorisation number for your treatment which you
				enter into the App. The Company is not responsible for this pre-authorisation or verification. Insufficient
				documentation will prevent direct settlements and may result in the account being billed to you. Any shortfalls
				in benefit are your responsibility. Should your insurer determine that the care and treatment is not covered, or
				not fully covered, under your insurance policy, you agree that you will be liable for the portion of the charges
				that are not settled by the insurer.
			</Text>
			<Text variant="body">
				6.8 You agree to provide only valid pre-authorisation details and insurance processing requests through the App.
			</Text>
			<Text variant="body">
				6.9 You understand and acknowledge that subscriptions will automatically renew upon the end of the agreed term,
				unless you provide a written request to the Company to cancel the subscription prior to the end of the relevant
				subscription period.
			</Text>
			<Text variant="body">
				6.10 Subscriptions available within the App/ USSD Service are monthly subscriptions, payable up-front in
				advance. For your convenience, the per-month price may be displayed.
			</Text>
			<Text variant="h3">7. OUR LIABILITY TO YOU</Text>
			<Text variant="body">
				7.1 If the Company fails to comply with these Terms, the Company is responsible for loss or damage you suffer
				that is a foreseeable result of the Company’s breach of these Terms or the Company’s negligence, but the Company
				is not responsible for any loss or damage that:
			</Text>
			<Text variant="body">
				<Text variant="body">
					<ol>
						<li>
							Is not foreseeable. Loss or damage is foreseeable if it is an obvious and direct consequence of our breach
							or if it is contemplated by you and the Company at the time the Company entered into this contract; or
						</li>
						<li>
							Has been caused by a relevant Practitioner (except to the extent such Practitioner is an employee of the
							Company acting within the scope of their employment), Affiliate, subcontractor or third party. Such
							Practitioner, Affiliate, subcontractor or third party person shall be solely liable for that loss or
							damage unless and to the extent that it is caused by the negligence, material breach or wilful default of
							the Company.
						</li>
					</ol>
				</Text>
			</Text>
			<Text variant="body">
				7.2 Partnership companies are not responsible for any loss or damage that has been caused by the Company, a
				Practitioner, an Affiliate or subcontractor or third party and the Company or the Practitioner, Affiliate,
				subcontractor or third party shall be solely liable for that loss or damage.
			</Text>
			<Text variant="body">
				7.3 The App is not suitable for managing a medical emergency or acute condition, any condition that should
				reasonably require face to face analysis, diagnosis or treatment, or for sourcing any product or service
				urgently. The company cannot guarantee continuous availability of any Practitioner or any availability of any
				particular Practitioner
			</Text>
			<Text variant="body">
				7.4 Appropriate technical and organisational measures shall be taken against unauthorised or unlawful processing
				of personal data and against accidental loss or destruction of, or damage to, personal data but subject to that
				and the Company’s obligations under law as a data controller, the Company is not responsible for any data loss
				or unauthorised access by other persons, whether a result of unauthorised access, misaddress, technical failure,
				technical interference, negligence or otherwise. The Company reserves the right to withdraw or delete any
				information from the App/ USSD Service at any time. For the avoidance of doubt, the company cannot be held
				responsible for the security of personal data on any device that has had its operating system modified (such as
				being jailbroken or rooted).
			</Text>
			<Text variant="body">7.5 Except as set out under clause 7.7, the Company:</Text>
			<Text variant="body">
				<ol>
					<li>Otherwise disclaims all warranties, express or implied;</li>
					<li>
						Does not warrant that the App, the Company’s servers, or e-mail sent from the Company are free of viruses or
						other harmful components.
					</li>
					<li>
						Does not warrant that the App, the Company’s servers, or e-mail sent from the Company are free of viruses or
						other harmful components.
					</li>
					<li>
						Will not be liable for any damages of any kind arising from the use of the App/ USSD Service, including, but
						not limited to:
					</li>
					<li>Indirect;</li>
					<li>Incidental;</li>
					<li>Punitive; an/or</li>
					<li>Consequential damages.</li>
				</ol>
			</Text>
			<Text variant="body">
				7.6 The Company provides, among other things, a communications platform for you to communicate with
				Practitioners and to order products and services from third parties. Except in respect of the actions or
				omissions of the Company or its officers and employees acting within the scope of their employment, the Company
				does not accept any liability or responsibility for the actions or omissions of any third party, in particular
				its Affiliates and any Practitioners who are not employees of the Company.
			</Text>
			<Text variant="body">
				7.7 The Company only supplies the Products and Services for domestic and private use. You agree not to use the
				Products and/or Services for any commercial, business or re-sale purpose, and the Company has no liability to
				you for any:
			</Text>
			<Text variant="body">
				<ol>
					<li>Loss of profit;</li>
					<li>Loss of business;</li>
					<li>Business interruption; or</li>
					<li>Loss of business opportunity.</li>
				</ol>
			</Text>
			<Text variant="body">7.8 The Company does not exclude or limit in any way its liability for:</Text>
			<Text variant="body">
				<ol>
					<li>
						Death or personal injury caused by the Company’s negligence or the negligence of its employees, agents or
						subcontractors;
					</li>
					<li>Fraud or fraudulent misrepresentation;</li>
					<li>Unsafe or defective products under the Law relating to competition and consumer protection.</li>
					<li>For any other liability which cannot be excluded or limited by applicable law.</li>
				</ol>
			</Text>
			<Text variant="body">
				7.9 Without prejudice to clause 7.7 above, the liability of the Company in connection with the Services and any
				Product whether under contract, tort/delict, statute, common law or otherwise (and including for negligence or
				wilful default) shall not in any circumstances exceed:
			</Text>
			<Text variant="body">
				<ol>
					<li>
						[$2,000] in aggregate in relation to any Services and/or Products ordered through the Online Shop; and
					</li>
					<li>[$1,000,000] in aggregate otherwise.</li>
				</ol>
			</Text>
			<Text variant="h3">8. EVENTS OUTSIDE OUR CONTROL</Text>
			<Text variant="body">
				8.1 The Company will not be liable or responsible for any failure to perform, or delay in performance of, any of
				its obligations under these Terms that is caused by an Event Outside Our Control.
			</Text>
			<Text variant="body">
				8.2 An Event Outside Our Control means any act or event beyond the Company’s reasonable control, including
				without limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot,
				invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation
				for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure
				of public or private telecommunications networks. For the avoidance of doubt, an Event Outside Our Control
				includes any action or failure to act by the relevant Practitioner and/or insufficient/variable network
				connectivity at any time for the User, Company or Practitioner.
			</Text>
			<Text variant="body">
				8.3 If an Event Outside Our Control takes place that materially affects the performance of the Company’s
				obligations to you under these Terms:
			</Text>
			<Text variant="body">
				<ol>
					<li>The Company will notify you; and</li>
					<li>
						The Company’s obligations under these Terms will be suspended and the time for performance of its
						obligations will be extended for the duration of the Event Outside Our Control. Where the Event Outside Our
						Control affects the Company’s performance of Services to you, the Company will resume the Services as soon
						as reasonably possible after the Event Outside Our Control is over.
					</li>
				</ol>
			</Text>
			<Text variant="body">
				8.4 You may cancel the contract immediately if an Event Outside Our Control takes place resulting in a
				suspension of the Services that continues for longer than 2 weeks and you no longer wish the Company to provide
				the Services. Please see your cancellation rights under clause 9. The Company may cancel the contract if an
				Event Outside Our Control continues for longer than 2 weeks.
			</Text>
			<Text variant="h3">9. YOUR RIGHTS TO CANCEL AND APPLICABLE REFUNDS</Text>
			<Text variant="body">
				9.1 Subject to Clause 9.3, you have the following rights regarding (i) cancellation of the Services (including
				where you choose to cancel because the Services are affected by an Event Outside Our Control or if the Company
				change these Terms under clause 3.1 to your disadvantage); and (ii) refunds, where you have made a payment for
				a:
			</Text>
			<Text variant="body">
				<ul>
					<li>Monthly subscription you may cancel the Services and you shall:</li>
					<ol>
						<li>
							Be entitled to a refund of your subscription, in whole or in part if you choose to cancel the Services
							because the Company has changes the Terms to your disadvantage or the Company breaks this contract in any
							material way and the Company does not correct or fix the situation within 14 business days of you asking
							the Company to in writing;
						</li>
						<li>
							Not be entitled to a refund of your subscription, in whole or in part where you choose to cancel because
							the Services are affected by an Event Outside Our Control
						</li>
					</ol>
					<li>
						Non-subscription Service that you request from time to time (including, without limitation, fees for
						consultations with Specialists), you may cancel such requests by providing reasonable (and, in any case,
						greater than 24 hours) notice to the Company but you acknowledge and accept that the Company may begin to
						procure, resource, prepare, organise and/or undertake provision of a Service as soon as you request the
						Service and, accordingly, any costs the Company has reasonably incurred in procuring, resourcing, preparing,
						organising or undertaking the relevant Service shall be deducted from any refund that is due to you, and
						such deduction shall be a minimum of 5000 RWF in respect of Services provided in Rwanda. You shall not be
						entitled to any refund for any Services that cannot be fulfilled due to your own absence (including, without
						limitation, for a scheduled consultation), insufficient or variable network connectivity, negligence or
						other relevant error.
					</li>
				</ul>
			</Text>
			<Text variant="body">
				9.2 Once the Company has begun to provide the Services to you, you may cancel the contract for Services with
				immediate effect by giving the Company written notice if:
			</Text>
			<Text variant="body">
				<ol>
					<li>
						The Company breaks this contract in any material way and the Company does not correct or fix the situation
						within 14 business days of you asking the Company to in writing;
					</li>
					<li>The Company goes into liquidation or a receiver or an administrator is appointed over its assets;</li>
					<li>The Company changes these Terms under clause 3.1 to your disadvantage;</li>
					<li>The Company is affected by an Event Outside Our Control in the circumstances set out in clause 8.4.</li>
				</ol>
			</Text>
			<Text variant="body">
				9.3 No refund shall be due because of any unavailability of any particular Practitioner where the App/ USSD
				Service is functioning and an alternative Practitioner is available, or, for the avoidance of doubt, in the
				event of the unavailability of any feature or function of the App/ USSD Service that is available to Users free
				of charge. Nor shall any refund be due because of any temporary interruption in service. You accept by using the
				App that Practitioners may vary in their opinions and provided any opinion and/or advice and/or recommendation
				and/or prescription is given in good faith by a relevant Practitioner, no refund shall be due in respect of
				Services provided.
			</Text>
			<Text variant="body">RIGHT TO CANCEL</Text>
			<Text variant="body">
				9.4 You acknowledge that you cannot cancel an order for the supply of digital content or Services which have
				been fully performed, where we started delivery of the digital content or performance of the Services upon your
				request.
			</Text>
			<Text variant="body">
				9.5 To cancel your order under clause 10 You must inform us (Babylon Rwanda Ltd, Floor 6, EcoBank Building, Plot
				314, Avenue de la Paix, Kigali, Rwanda. Email (support@Babyl.rw) of your decision to cancel this contract by an
				unequivocal statement (e.g. e-mail or letter). To meet the cancellation deadline, you must send your
				communication of cancellation before the 14 day period has expired.
			</Text>
			<Text variant="body">
				9.6 If you cancel this contract under clause 10, we will reimburse to you all payments received from you,
				including the costs of delivery (with the exception of the supplementary costs resulting from your choice of a
				type of delivery other than the least expensive type of standard delivery offered by us) without undue delay and
				in any event not later than 14 days from the day on which we are informed about your decision to cancel this
				contract. We will carry out such reimbursement using the same means of payment as you used for the initial
				transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result
				of such reimbursement.
			</Text>
			<Text variant="h3">10. OUR RIGHTS TO CANCEL AND APPLICABLE REFUND</Text>
			<Text variant="body">
				10.1 The Company may have to cancel a request for Services before the start date for the Services, due to an
				Event Outside Our Control or the unavailability of key personnel or key materials without which the Company
				cannot provide the Services. The Company will promptly notify you if a Service is unavailable.
			</Text>
			<Text variant="body">
				10.2 Once the Company has begun to provide the Services to you, the Company may cancel the contract for the
				Services at any time by providing you with at least 15 calendar days’ notice in writing.
			</Text>
			<Text variant="body">
				10.3 If the Company has to cancel provision of a Service or the Services under clause 10.1or clause 10.2 and you
				have made any payment in advance for the Service or Services that has not been provided to you:
			</Text>
			<Text variant="body">
				<ol>
					<li>
						In the case of a Service you have requested and paid for via a monthly subscription payment, the Company
						shall use reasonable endeavours to restore the affected Service per clause 4.2 and no refund of such
						subscription payment shall be due where the Company has cancelled the Services due to an Event Outside Our
						Control;
					</li>
					<li>
						In the case of a Service you have requested and paid for via a non-subscription payment, the Company shall
						refund the individual payment to you;
					</li>
					<li>
						For the avoidance of doubt, in the case of a Service you have requested that ordinarily is available free of
						charge to Users, you shall not be eligible to receive any refund;
					</li>
					<li>
						For the avoidance of doubt, no refunds shall be payable in the event that a Service that you have requested
						cannot be provided due to your own absence (including, without limitation, for a scheduled consultation),
						negligence, failure to provide requested information or other relevant error.
					</li>
				</ol>
			</Text>
			<Text variant="body">
				10.4 The Company may cancel the contract for Services at any time with immediate effect by giving you written
				notice if:
			</Text>
			<Text variant="body">
				<ol>
					<li>You do not pay the Company when you are supposed to as set out in these Terms; or</li>
					<li>
						You break the contract in any other material way and you do not correct or fix the situation within 7
						calendar days of the Company asking you to in writing, or sooner if reasonably required.
					</li>
				</ol>
			</Text>
			<Text variant="body">
				10.5 From time to time, the Company will request certain information from you that may be confidential and/or
				covered by data protection legislation – for example, details of any medical conditions. The Company will
				contact you in writing about this. If you do not, after being asked by the Company, provide the Company with
				this information, or you provide the Company with incomplete or incorrect information, the Company may suspend
				the Services by giving you written notice. The Company will not be liable for any delay or non-performance where
				you have not provided this information to the Company after the Company have asked for such information and the
				Company will not be obliged to provide any refund.
			</Text>
			<Text variant="h3">11. ELECTRONIC COMMUNICATIONS</Text>
			<Text variant="body">
				11.1 When you use the App/ USSD Service or send e-mails to us, you are communicating with us electronically. You
				consent to receive communications from us electronically. We will communicate with you by e-mail or by posting
				notices, alerts, prompts, SMS messages, information fields or other information through the App/ USSD Service.
				You agree that all agreements, notices, disclosures and other communications that we provide to you
				electronically satisfy any legal requirement that such communications be in writing.
			</Text>
			<Text variant="h3">12. YOUR OBLIGATIONS</Text>
			<Text variant="body">12.1 You undertake that:</Text>
			<Text variant="body">
				<ol>
					<li>
						All information you give to the Company, its Affiliates and any Practitioner will be accurate and not omit
						anything of importance;
					</li>
					<li>You will comply with all instructions and advice given to you by any Practitioner;</li>
					<li>
						If, following use of the App/ USSD Service, you are in any doubt, or have any concerns regarding any
						information or advice you have received or failed to receive via the Services, or regarding your health,
						wellbeing or any conditions, you agree to seek further medical opinion from a registered GP or suitable
						healthcare professional not affiliated with the Services and/or shall utilise the emergency services as
						applicable.
					</li>
					<li>
						You will comply with any instructions given in relation to any prescription given to you including in
						particular route, timing and dosages;
					</li>
					<li>You will promptly report any side effects of any prescription to a Practitioner.</li>
					<li>
						You will protect anything prescribed to you and not permit any other person access to the prescription;
					</li>
					<li>You will not use any prescription after its expiry date;</li>
					<li>You will not register more than once with the App/ USSD Service;</li>
					<li>
						Subject to clause 16 you will not register on behalf of any other person or answer questions or pretend to
						be any other person;
					</li>
					<li>
						You will promptly notify the Company if you are aware that any part of your medical record in the App or
						held by the Company including any tests, prescriptions, imaging, advice given or opinion expressed is
						incomplete or inaccurate; and
					</li>
					<li>
						To help us protect the privacy and security of your information, you agree not to make independent
						recordings of consultations. Your consultations are available for playback within the App.
					</li>
				</ol>
			</Text>
			<Text variant="h3">13. INTELLECTUAL PROPERTY</Text>
			<Text variant="body">
				13.1 The content of the Company’s website, App, USSD Service and other Materials is protected by copyright,
				trademarks, database right and other intellectual property rights.
			</Text>
			<Text variant="body">
				13.2 You may retrieve and display the content of the Company’s App/ USSD Service and/or any information stored
				on or procured through it on a computer or device screen, store such content in electronic form on disk or print
				copies of such content for your own personal and non-commercial use, provided you keep intact all and any
				copyright and proprietary notices.
			</Text>
			<Text variant="body">
				13.3 Consultations, activity and data inputted in the App/ USSD Service may be recorded by the Company and you
				consent to such recording, storage and subsequent retrieval by the Company. You may view consultation recordings
				from within the App for your own private purposes (and may show them to any other medical practitioner who is
				supporting your care) but you acknowledge that all intellectual property rights in these belong to the Company.
			</Text>
			<Text variant="body">
				13.4 You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the
				materials or content of the Company’s App/ USSD Service without written permission from the Company. You do not
				obtain any right in the copyright, design right or any other intellectual property rights in any materials or
				other documents or items made available to you on the Company’s App/ USSD Service or through your use of the
				Services.
			</Text>
			<Text variant="body">
				13.5 You shall be entitled to use any such materials, documents or other items as are referred to in paragraphs
				13.1 to 13.4 above for personal use and only in connection with the Services but shall not be entitled to copy,
				edit, alter or disseminate (including but not limited to, distributing or making the materials, documents or
				other items available on a file-sharing network) any such items (in whole or in part) or use them for any
				commercial purpose. For the avoidance of doubt, nothing in these Terms restricts you from using your medical
				information for your own personal use including passing to any other medical practitioner.
			</Text>
			<Text variant="body">
				13.6 All content included in the App, such as text, graphics, logos, button icons, images, audio clips, digital
				downloads, data compilations and software, is the property of the Company or its content suppliers and protected
				by national and international copyright laws. The compilation of all content in the App/ USSD Service is the
				exclusive property of the Company. All software used in the App/ USSD Service is the property of the Company or
				its software suppliers and protected by national and international copyright laws.
			</Text>
			<Text variant="h3">14. TRADEMARKS</Text>
			<Text variant="body">
				The Babyl mark, logo, combined mark and logo and other marks indicated in our App/ USSD Service are trademarks
				of the Company or its affiliates in the United Kingdom, Northern Ireland, Rwanda and other countries. Other
				graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade dress of the
				Company or its Affiliates.
			</Text>
			<Text variant="h3">15. LICENSE AND APP USE</Text>
			<Text variant="body">
				The Company grants you a limited license to access and make personal use of the App/ USSD Service and not to
				modify it, or any portion of it, except with express written consent of the Company. This license does not
				include any resale or commercial use of the App/ USSD Service or its contents; any derivative use of the App/
				USSD Service or its contents; or any use of data mining, robots, or similar data gathering and extraction tools.
				The App/ USSD Service or any portion of the App/ USSD Service may not be reproduced, duplicated, copied, sold,
				resold, visited, or otherwise exploited for any commercial purpose without express written consent of the
				Company. Any unauthorised use terminates the permission or license granted by the Company. You may not use any
				logo or other proprietary graphic or trademark of the Company or its Affiliates as part of the link without our
				express written permission.
			</Text>
			<Text variant="h3">16. YOUR USE OF THE COMPANY’S SYSTEMS</Text>
			<Text variant="body">
				Persons aged 18 years or older may use the App/ USSD Service on their own, via their own account, via payment
				made using their own credit card, debit card or mobile money account. Persons aged 16 years to 18 years may use
				the App on their own, via their own account, with payment provided by a credit card, debit card or mobile money
				account belonging to a parent, legal guardian or other adult individual who has consented for their credit card,
				debit card or mobile money account to be used for such purposes.
			</Text>
			<Text variant="body">
				Persons older than 28 days and under 16 years of age (a “Minor”) may have their account opened by a parent or
				legal guardian on their behalf with the App but (i) the Minor’s account shall be accessible only by switching
				profiles within the adult’s account to which it is attached; and (ii) the Minor may use the App and the Services
				only with the express permission and ongoing presence and supervision of the parent or legal guardian. The
				Company and its Affiliates reserve the right to refuse to provide Services and/or Products, terminate accounts,
				or remove or edit content where they have a reasonably held suspicion that a Minor is using the App/ USSD
				Service and/or other Services contrary to the provisions of this clause 16.
			</Text>
			<Text variant="h3">17. INTERNATIONAL USE</Text>
			<Text variant="body">
				The App may only be used within the United Kingdom, Ireland and Rwanda and may not be used in any circumstances
				where the laws of any jurisdiction outside the above countries would apply to its use, or the provision of the
				Services or Products.
			</Text>
			<Text variant="body">
				The USSD Service may only be used within Rwanda and may not be used in any circumstances where the laws of any
				jurisdiction outside Rwanda.
			</Text>
			<Text variant="body">
				The Company makes no promise that materials on the Company’s website, App, Systems and Online Shop are
				appropriate or available for use in locations outside the United Kingdom, Ireland and Rwanda and accessing the
				Company’s website, App, Systems and Online Shop from territories where its contents are illegal or unlawful is
				prohibited.
			</Text>
			<Text variant="body">
				If in contravention of these Terms you access the Company’s website, App, USSD Service and Systems from
				locations outside Rwanda, you do so on your own initiative and are responsible for compliance with local laws.
			</Text>
			<Text variant="h3">18. ABUSIVE USE</Text>
			<Text variant="body">
				The Company reserves the right to deny any person (inclusive of any User) access to the App/ USSD Service and/or
				its Services and Products in the event that the person is abusive or inappropriate to any Practitioner or any
				employee or agent of the Company. Inappropriate use includes, without limitation: (i) inappropriate, abusive,
				offensive behaviour or remarks; or (ii) ‘time-wasting’; or (iii) excessive use of a Service or Services in the
				reasonable opinion of the Company and/or relative to typical usage patterns in the reasonable opinion of the
				Company. In the event of inappropriate or abusive use, the Company reserves the right to terminate provision of
				Services or Products and use of the App (including deletion of the User’s account and information) immediately
				upon notice.
			</Text>
			<Text variant="h3">19. SERVICES DESCRIPTION</Text>
			<Text variant="body">
				The Company and its Affiliates attempt to be as accurate as possible in their description of the Services.
				However, the Company does not warrant that descriptions of services or other content of the App/ USSD Service is
				accurate, complete, reliable, current, or error-free. The Company provides links to other sites and maintains
				feeds from external data providers over which the Company has no control. The Company is not responsible for the
				availability of such external sites or resources and does not endorse and is not responsible or liable for any
				content, advertising, products or other material on or available from such sites or resources.
			</Text>
			<Text variant="h3">20. DISPUTES</Text>
			<Text variant="body">
				20.1 If any dispute arises in connection with these Terms, the parties will attempt to resolve the dispute
				between the parties. If the dispute is not resolved within 60 days of the initial notification of a dispute by
				either party, any and all disputes shall be submitted to Kigali International Arbitration center (KIAC) and
				shall be finally settled through binding arbitration under the Rules of Arbitration in effect on the date that
				such notice of dispute is given.
			</Text>
			<Text variant="body">
				20.2 The arbitration shall be conducted before a panel of three (3) arbitrators. Each Party shall nominate one
				arbitrator each and the two (2) arbitrators shall mutually select a third arbitrator, who shall serve as the
				Chair of the arbitration panel. If no agreement on the third arbitrator can be reached within thirty (30) days
				of the confirmation of the second arbitrator, then the Chairman shall be appointed by KIAC. The seat of the
				arbitration shall be in Kigali, Rwanda, and the arbitral proceedings shall be in the English language. Either
				party may seek interim injunctive relief from a court, which shall not be deemed as a waiver of any provision in
				this section. The arbitral tribunal shall also have authority to make such orders for interim relief as well as
				injunctive relief. The prevailing party in the arbitration shall recover costs and expenses of the arbitration,
				as well as reasonable attorney’s fees.
			</Text>
			<Text variant="h3">21. APP POLICIES, MODIFICATION, AND SEVERABILITY</Text>
			<Text variant="body">
				21.1 We reserve the right to make changes to our App/ USSD Service at any time and, subject to clause 10.3, to
				withdraw any Service by providing you with reasonable notice.
			</Text>
			<Text variant="body">
				21.2 If any of these Terms shall be deemed invalid, void, or for any reason unenforceable, that condition shall
				be deemed severable and shall not affect the validity and enforceability of any remaining condition.
			</Text>
			<Text variant="h3">22. HOW TO CONTACT THE COMPANY</Text>
			<Text variant="body">
				22.1 The Company is a limited company registered in Rwanda. The company registration number is 105126570 and the
				registered office is at Kimihurura, Gasabo, Umujyi wa Kigali, Rwanda. The Company operates the website
				www.Babylhealth.rw. The main trading address of the Company is Floor 6, Ecobank Building, Plot 314, Avenue de la
				Paix, Kigali, Rwanda.
			</Text>
			<Text variant="body">
				22.2 If you have any questions or if you have any complaints, please contact the Company. You can contact the
				Company by telephoning the Membership Service Team at 8111 or by e-mailing the Company at
				support@Babylrwanda.com.
			</Text>
			<Text variant="body">
				22.3 If you wish to contact the Company in writing, or if any clause in these Terms requires you to give the
				Company notice in writing (for example, to cancel the contract), you can send this to the Company by e-mail, by
				hand, or by pre-paid post to Babylon Rwanda Limited at Floor 6, Ecobank Building, Plot 314, Avenue de la Paix,
				Kigali, Rwanda. The Company will confirm receipt of this by contacting you by phone. If the Company has to
				contact you or give you notice in writing, the Company will do so by e-mail, by hand, in writing or by pre-paid
				post to the address you provide to the Company upon agreement by the Company to provide the Services.
			</Text>
			<Text variant="h3">23. HOW THE COMPANY MAY USE YOUR PERSONAL INFORMATION</Text>
			<Text variant="body">23.1 The Company will use the personal information you provide to the Company to:</Text>
			<Text variant="body">
				<ol>
					<li>Provide the Products and Services;</li>
					<li>
						Provide to Affiliates of the Company where reasonable or necessary in relation to the provision of the
						Products and Services, including provision of your information to Practitioners including Nurses, GPs,
						Specialists, dispensing chemists, partner pathology (testing) providers, and suppliers or subcontractors
						used to provide Services and Products;
					</li>
					<li>Process your payment for such Products and Services;</li>
					<li>
						Review and enhance the quality of the Services, including monitoring compliance with clinical care
						standards;
					</li>
					<li>
						Comply with reasonable requests by regulatory bodies including the Rwanda Medical and Dental Council,
						Nursing and Midwifery Council, Ministry of Health or as otherwise required by law or regulation;
					</li>
					<li>
						Where you request it or have otherwise consented, provide it to your insurance company(y/ies) who may
						contribute to the cost of the Products or Services you request to use;
					</li>
					<li>Provide information to your other healthcare providers; or</li>
					<li>The detection of fraud.</li>
				</ol>
			</Text>
			<Text variant="body">
				23.2 We only use your personal information in accordance with our Privacy Policy. For details, please see our
				Privacy Policy. Please take the time to read these, as they include important terms that apply to you and are
				integrated into these Terms.
			</Text>
			<Text variant="body">
				23.3 We will not provide your personal information to any other persons or use it for any other purpose except
				as set out in this clause and in the Privacy Policy.
			</Text>
			<Text variant="body">
				23.4 If you elect to pre-authorise a treatment via an insurance company, you acknowledge and agree that it may
				be necessary to provide access to your contact, personal, medical, treatment, outcomes or other information, to
				your insurer to facilitate your request and the insurer’s quality control and clinical practice requirements.
				Alternatively, the insurer may contact you with a separate consent form, requesting that you allow them to
				request information from us relating to your claim.
			</Text>
			<Text variant="body">
				23.5 The Company retains the right to use anonymised data for purposes including (without limitation) research.
			</Text>
			<Text variant="body">
				23.6 The Company uses a third party to process payments and refunds. We do not store your credit or debit card
				details. Credit/ debit card details are stored securely with a third party in the eventuality of payment being
				required for future purchases or shortfalls in payments by insurers.
			</Text>
			<Text variant="body">
				23.7 Authorised members of the Company’s clinical team may utilise consultation recordings for audit and quality
				monitoring purposes.
			</Text>
			<Text variant="h3">24. OTHER IMPORTANT TERMS</Text>
			<Text variant="body">
				24.1 The Company may transfer its rights and obligations under these Terms to another organisation, and the
				Company will always notify you in writing if this happens, but this will not affect your rights or the Company’s
				obligations under these Terms.
			</Text>
			<Text variant="body">
				24.2 You may only transfer your rights or your obligations under these Terms to another person if the Company
				agrees in writing.
			</Text>
			<Text variant="body">
				24.3 Notwithstanding that this contract has a number of Schedules which may apply to one or more of the
				particular Services or Products supplied by the Company, there are one set of terms and may only be accepted or
				declined in full.
			</Text>
			<Text variant="body">
				24.4 The Terms are the terms of the contract between you and the Company. No other person shall have any rights
				to enforce any of the Terms. The Company may enforce the Terms on behalf of Affiliates.
			</Text>
			<Text variant="body">
				24.5 If an Affiliate which is a third party provides a Service or Product through the App/ USSD Service, such
				third party may apply its terms and conditions to the Service or Product provided by it and such terms and
				conditions will form the basis of a contract between you and the third party.
			</Text>
			<Text variant="body">
				24.6 Each of the clauses of these Terms operates separately. If any court or relevant authority decides that any
				of them are unlawful, the remaining clauses will remain in full force and effect.
			</Text>
			<Text variant="body">
				24.7 If the Company fails to insist that you perform any of your obligations under these Terms, or if the
				Company does not enforce its rights against you, or if the Company delays in doing so, that will not mean that
				the Company has waived its rights against you and will not mean that you do not have to comply with those
				obligations. If the Company does waive a default by you, the Company will only do so in writing, and that will
				not mean that the Company will automatically waive any later default by you.
			</Text>
			<Text variant="body">
				24.8 These Terms are governed by Rwandan law. You and the Company both agree to submit to the non-exclusive
				jurisdiction of the Rwandan courts.
			</Text>
			<Text variant="h2">
				SCHEDULE 1 – CONSULTATIONS TERMS AND CONDITIONS (COVERING VIDEO, AUDIO AND TEXT CONSULTATIONS)
			</Text>
			<Text variant="h3">1. NATURE OF ADVICE GIVEN VIA THE SERVICES AND RELIANCE</Text>
			<Text variant="body">
				1.1 Certain Practitioners are employed by the Company and other Practitioners are independent third parties. The
				Company has taken reasonable steps to verify the identity of Practitioners against their regulatory body’s
				database of members and in the normal course of events will use reasonable efforts to verify certain background
				information about a Practitioner.
			</Text>
			<Text variant="body">
				1.2 Consultations are subject to Practitioner availability. When you place an order for a consultation with a
				Practitioner, the Company shall use reasonable efforts to offer a consultation within a reasonable period of
				time, but the Company does not guarantee to offer a consultation within a particular time frame.
			</Text>
			<Text variant="body">
				1.3 By using a text, audio and/or video consultation, you consent to the use of a remote service.
			</Text>
			<Text variant="body">
				1.4 You acknowledge that while the Company takes all reasonable precautions, due to the nature of electronic
				communications the security of a remote interaction cannot be guaranteed.
			</Text>
			<Text variant="body">
				1.5 You understand and agree that triage consultations maybe limited to 5 minutes and consultations with GPs and
				Nurses may be limited to 10 minutes in duration. Consultations with Specialist may be limited to 20 minutes in
				duration.
			</Text>
			<Text variant="body">
				1.6 Advice, information or other communications given or made remotely by audio or video are by their nature
				limited relative to a face-to-face consultation. A non-face to face consultation may therefore not be sufficient
				in certain circumstances and Users should be aware of, and by using the Company’s App/ USSD Service and any
				other facility are deemed to have accepted, those limitations. In particular, a non-face to face consultation is
				unlikely to be appropriate or sufficient in circumstances of: (i) emergency, (ii) urgency, (iii) where a
				physical examination is likely to be required, (iv) where physical intervention is likely to be required, or (v)
				where the problem, issue or information required is not suited to remote communication.
			</Text>
			<Text variant="body">
				1.7 It is the responsibility of the User to evaluate whether any advice received via the Services is suitable or
				sufficient for the User’s needs, to what extent the User should rely upon it and whether the User should seek
				the assistance of a GP, other medical practitioner or emergency services.
			</Text>
			<Text variant="body">
				1.8 If, following use of the App/ USSD Service, you are in any doubt, or have any concerns regarding any
				information or advice you have received or failed to receive via the Services, or regarding your health,
				wellbeing or any conditions, you agree to seek further medical opinion from a registered GP or suitable
				healthcare professional not affiliated with the Services and/or shall utilise the emergency services as
				applicable.
			</Text>
			<Text variant="h3">2. AMENDMENT OF THE TERMS</Text>
			<Text variant="body">
				A User may separately agree terms and conditions with a Practitioner in relation to the Terms upon which that
				Practitioner is willing to provide services to the User. If that is the case, such agreement shall not affect
				these terms to the extent they apply to the relationship between the User and the Company.
			</Text>
			<Text variant="h3">3. COMMITMENT TO YOU</Text>
			<Text variant="body">3.1 Each of our Practitioners who is a GP or Nurse has committed:</Text>
			<Text variant="body">
				<ol>
					<li>
						To provide a professional and transparent service that complies with the General Medical and Dental Council
						Guidelines or Nursing and Midwifery Council and clinical best practice in Rwanda.
					</li>
					<li>
						To volunteer where requested their name and General Medical Council and Dental Council / Nursing and
						Midwifery Council professional registration number when prescribing medicines (applicable to GPs only) and
						communicating with patients;
					</li>
					<li>
						To prescribe medicines only when in their professional judgment it is in the patient’s best interests to
						receive the medicine being requested and, accordingly, to refuse to prescribe medicines when they believe it
						is not in the patient’s best interests to receive medicines without the benefit of a face to face
						consultation; and
					</li>
					<li>To take all reasonable steps to protect patients’ personal information.</li>
				</ol>
			</Text>
			<Text variant="h2">SCHEDULE 2 – ADDITIONAL TERMS IN RELATION TO THE END USER LICENCE FOR THE APP</Text>
			<Text variant="body">
				Operating system requirements This App requires an iPhone version 4 or more recent, running iOS 6 or higher, or
				an Android smartphone running Android v4.2 or more recent, and a consistent 3G (or faster) data connection. For
				the avoidance of doubt, GPRS or EDGE connections are not suitable (“Devices”).
			</Text>
			<Text variant="body">
				We recommend that if you are using a wireless network to access the App, you avoid use of public wi-fi
				facilities in favour of a personal wi-fi connection, and that the wireless network is secured with WPA-2
				security. We recommend that the device from which you access the App is password protected, set to lock after a
				short period of inactivity, and protected with suitable anti-virus and anti-malware software.
			</Text>
			<Text variant="body">
				From time to time, updates to the App may be made available to you. We recommend that you use the latest version
				of the App at all times, to take advantage of the latest enhancements.
			</Text>
			<Text variant="h3">1. ACKNOWLEDGEMENTS</Text>
			<Text variant="body">
				1.1 If any open-source software is included in the App or any Service, the terms of an open-source licence may
				override some of the terms of this end user licence.
			</Text>
			<Text variant="body">
				1.2 From time to time updates to the App may be issued. Depending on the update, you may not be able to use the
				Services until you have downloaded or streamed the latest version of the App and accepted any new terms.
			</Text>
			<Text variant="body">
				1.3 By using the App or any of the Services, you consent to us collecting and using technical information about
				the Devices and related software, hardware and peripherals for Services that are internet-based or wireless to
				improve our products and to provide any Services to you.
			</Text>
			<Text variant="body">
				1.4 Certain Services, including Prescriptions, may make use of location data sent from the Devices. You can turn
				off this functionality at any time by turning off the location services settings for the App on your device. If
				you use these Services, you consent to us and our Affiliates’ and licensees’ transmission, collection,
				maintenance, processing and use of your location data and queries to provide and improve location-based products
				and services. You may withdraw this consent at any time by turning off the location services settings on your
				device.
			</Text>
			<Text variant="body">
				1.5 The App or any Service may contain links to other independent third-party websites (Third-party Sites).
				Third-party Sites are not under our control, and we are not responsible for and do not endorse their content or
				their privacy policies (if any). You will need to make your own independent judgement regarding your interaction
				with any Third-party Sites, including the purchase and use of any products or services accessible through them.
			</Text>
			<Text variant="h3">2. PURPOSE OF APP</Text>
			<Text variant="body">
				2.1 You acknowledge that the App has not been developed to meet your individual requirements, and that it is
				therefore your responsibility to ensure that the facilities and functions of the App meet your requirements.
			</Text>
			<Text variant="body">
				2.2 The App is not a medical device. While certain information controlled, generated by, displayed within or
				stored in the App (including, without limitation, by the ‘Monitor Me feature) may be helpful in providing
				warning of certain medical or health conditions or circumstances, the App is not designed, nor may you use it
				as, a device to detect, diagnose, treat or monitor any medical or health condition or to establish the existence
				or absence of any medical or health condition. No responsibility is accepted in this regard by the Company or
				any of its Affiliates.
			</Text>
			<Text variant="h2">SCHEDULE 4 – ADDITIONAL TERMS IN RELATION TO PRESCRIPTIONS</Text>
			<Text variant="h3">1.PRESCRIBING DOCTORS</Text>
			<Text variant="body">
				1.1 In Rwanda, Services are provided by qualified doctors registered with the Rwandan General Medical and Dental
				Council who are permitted to prescribe medicines without meeting the patient face to face. The registration
				numbers with the General Medical and Dental Council of each doctor who is or was in the past registered with
				this service to provide prescriptions can be supplied upon request to the Company. All doctors that have signed
				to provide the service, have agreed to follow Ministry of Health guidelines and regulations for remote
				prescribing.
			</Text>
			<Text variant="h3">2. COLLECTION OR DELIVERY OF PRESCRIPTIONS</Text>
			<Text variant="body">
				2.1 From time to time, and subject to a range of factors including (without limitation) the nature of the items
				specified in a prescription and the availability of Affiliates, the App/ USSD Service may enable you to: (i)
				request that the Company sends your prescription to a pharmacy, for you to collect and fulfil during normal
				opening hours
			</Text>
			<Text variant="body">
				2.2 The Company will use reasonable efforts to provide appropriate pharmacy partners in all area of Kigali.
			</Text>
			<Text variant="body">
				2.3 Please note that the Company will use reasonable efforts to deliver the prescription to your device promptly
				but that the Company is not connected with and has no control over or responsibility for any individual
				pharmacy, their policies in relation to acceptance or otherwise of prescriptions, opening hours or availability.
				There are a number of factors which may impact on which pharmacy is chosen and there can be no guarantee that a
				pharmacy to which the prescription is delivered will be the nearest pharmacy.
			</Text>
			<Text variant="h3">3. THE COMPANY’S RELATIONSHIP WITH THE DISPENSING PHARMACY</Text>
			<Text variant="body">
				3.1 From time to time the Company has arrangements in place with Affiliate dispensing chemist/s in relation to
				orders placed for the fulfilment of prescriptions. The dispensing chemist is a separate entity from the Company
				and to the extent permitted by law the Company disclaims any liability for any acts or omissions of the
				dispensing chemist. From time to time the Company may enter into arrangements with other dispensing chemists.
			</Text>
			<Text variant="h2">TURAKUMVA</Text>
			<Text variant="body">Free health-line for youth</Text>
			<Text variant="h3">Terms and Conditions</Text>
			<Text variant="body">
				Turakumva is a free health-line for youth from Rwanda. It is a service designed for teenagers to call into Babyl
				specialized clinicians every weekday between 4pm and 7pm and ask any questions they have about their teen issues
				or seek health advice. Many young people don’t feel comfortable reaching out to their relatives or friends
				regarding private health issues, so we provide them with an alternative way to obtain information. The service
				is anonymous and confidential, only the gender and age will be asked and reported for the assessment of our
				impact on teenage girls.
			</Text>
			<Text variant="body">Our confidentiality promise will not apply if:</Text>
			<Text variant="body">
				<ul>
					<li>you ask us</li>
					<li>we believe your life or someone else’s life is in danger</li>
					<li>
						you’re being hurt by someone in a position of trust who has access to other young people like a teacher or
						police officer
					</li>
					<li>you tell us that you’re seriously harming another person.</li>
				</ul>
			</Text>
			<Text variant="body">
				We’ll always try to let you know if we need to break confidentiality. We want to help you in the best way and
				support you with whatever you are going through.
			</Text>
			<Text variant="h3">Deemed acceptance</Text>
			<Text variant="body">
				It is intended that these Terms of Use are legally binding on you and us. By accessing or using the Turakumva
				health-line, you are deemed to accept these Terms of Use which together with our Privacy Statement govern
				Babyl’s relationship with you in relation to the Turakumva health-line.
			</Text>
			<Text variant="body">
				Babyl may change these Terms of Use or our Privacy Statement at our discretion and without notice. By continuing
				to use the Turakumva health-line you accept the Terms of Use as they apply all the time.
			</Text>
			<Text variant="body">
				Your use of the Turakumva health-line and any dispute arising out of such use is subject to the laws of Rwanda.
			</Text>
			<Text variant="body">Important information</Text>
			<Text variant="body">
				THE TURAKUMVA HEALTH-LINE FOR YOUTH IS NOT INTENDED FOR USE IN EMERGENCY SITUATIONS. IF YOU ARE SUFFERING AN
				ACUTE ILLNESS OR EMERGENCY CONDITION, CALL 912 AND ASK FOR AN AMBULANCE.
			</Text>
			<Text variant="body">
				The information accessed through the Turakumva health-line for youth (e.g. via phone calls or the Facebook Chat)
				is for your general information and use only and is not intended to be used as medical advice.
			</Text>
			<Text variant="body">
				Information provided as part of the Turakumva health-line for youth may be used to assist you to manage
				non-acute medical conditions and locate medical assistance. However, this information is not a substitute for
				independent professional advice and should not be used as an alternative to professional health care. If you
				have a particular medical problem, please consult a healthcare professional. The Turakumva health-line is not a
				diagnostic tool.
			</Text>
			<Text variant="body">
				You should also not disregard professional advice, or delay seeking it, because of any information accessed
				through the Turakumva health-line or as a result of any advice received through the Facebook Chat.
			</Text>
			<Text variant="h3">Disclaimer of warranties</Text>
			<Text variant="body">
				While the health-line is subject to Babyl’s quality assurances processes, the information provided is also
				subject to continuing research, clinical experience, reasonable differences in opinions, unique aspects of
				individual situations and the possibility of human error in giving advice. No representation or warranty
				(express or implied) is made as to the currency, completeness, accuracy, reliability, suitability, and / or
				availability of the information given. In addition, no representation or warranty (express or implied) is made
				that the information will meet your health or medical requirements.
			</Text>
			<Text variant="body">
				Whilst we provide general health information, the circumstance of each individual will differ. You should
				exercise your own judgment with respect to your use of our health-line and you should carefully evaluate the
				currency, completeness, accuracy, reliability, suitability and relevance of the available information.
			</Text>
			<Text variant="h3">Disclaimer of liability</Text>
			<Text variant="body">
				You acknowledge that your use of any information given through the health-line is entirely at your own risk and
				that the information may contain inaccuracies or errors.
			</Text>
			<Text variant="body">
				To the maximum extent permitted by law, Babyl expressly disclaims all liability (including for negligence) for
				any loss, damage, injury, expense or costs incurred by you arising out of accessing, using or relying on any of
				the information or advice given through the health-line, but not limited to, in circumstances where:
			</Text>
			<Text variant="body">
				<ol>
					<li>the medical practitioner provides incorrect or inappropriate advice or information;</li>
					<li>the advice or information are used for a purpose for which they were not intended;</li>
					<li>the advice or information contain errors, omissions or inaccuracies;</li>
					<li>you are unable to obtain consistent, reliable and uninterrupted access to our health-line;</li>
					<li>
						you rely on an interpretation or opinion stated in, or which may be inferred from, the Turakumva health-line
					</li>
				</ol>
			</Text>
		</Wrapper>
	);
};
