import dayjs from 'dayjs';
import { TimelogEvent, Timestamp } from '../../Processes/CheckInProcess/types';
import { timeLogEvents } from './timeLogEvents';

export class TimeLogEvent {
	readonly event = {} as TimelogEvent;
	readonly timestamp: string = '';

	private static getEvent(key: string): TimelogEvent | ((value: string) => TimelogEvent) {
		return timeLogEvents[key];
	}

	private extractEventData() {
		return { ...this.event, timestamp: this.timestamp };
	}

	constructor(key: string, injectedValue?: string | null) {
		const mappedEvent = TimeLogEvent.getEvent(key);
		if (typeof mappedEvent === 'function') {
			try {
				if (injectedValue) this.event = mappedEvent(injectedValue);
			} catch {
				throw new Error('injectedValue parameter is required to calculate event_value');
			}
		} else {
			this.event = mappedEvent;
		}
		this.timestamp = dayjs().toISOString();
	}

	static construct = (name: string, value?: string | null): Timestamp =>
		new TimeLogEvent(name, value).extractEventData();
}
