import { BASENAME as localeReducerName, reducer as localeReducer } from './Locale/locale.slice';
import { BASENAME as healthCentreReducerName, reducer as healthCentreReducer } from './HealthCentre/healthCentre.slice';
import { BASENAME as registrationReducerName, reducer as registrationReducer } from './CheckIn/checkIn.slice';
import { BASENAME as timeLogReducerName, reducer as timeLogReducer } from './TimeLog/timeLog.slice';
import {
	BASENAME as catchmentAreaReducerName,
	reducer as catchmentAreaReducer,
} from './CatchmentArea/catchmentArea.slice';

// eslint-disable-next-line
export const rootReducer = () => ({
	[localeReducerName]: localeReducer,
	[healthCentreReducerName]: healthCentreReducer,
	[registrationReducerName]: registrationReducer,
	[timeLogReducerName]: timeLogReducer,
	[catchmentAreaReducerName]: catchmentAreaReducer,
});
