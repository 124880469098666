import styled from 'styled-components';
import { ButtonSimple } from '@babylon/medkit';

const Button = styled(ButtonSimple)`
	margin: 2rem 0;
	width: 100%;
`;

const Container = styled.div`
	align-items: center;
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	width: 19rem;
`;

export const HouseholdDetailsStyles = {
	Button,
	Container,
};
