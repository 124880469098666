import { defineMessages } from 'react-intl';

export default defineMessages({
	insuranceNotValid: {
		id: 'ErrorPage.insuranceNotValid',
		defaultMessage: 'Insurance not valid',
	},
	insuranceNotFound: {
		id: 'ErrorPage.insuranceNotFound',
		defaultMessage: 'Insurance not found',
	},
	insuranceExpired: {
		id: 'ErrorPage.insuranceExpired',
		defaultMessage: 'Insurance expired',
	},
	generalError: {
		id: 'ErrorPage.generalError',
		defaultMessage: "We're sorry, but something went wrong.",
	},
	next: {
		id: 'ErrorPage.next',
		defaultMessage: 'Next step',
	},
	nextInstructions: {
		id: 'ErrorPage.nextInstructions',
		defaultMessage: 'Speak to a RSSB (Rwanda Social Security Board) staff member',
	},
});
