import { useIntl } from 'react-intl';
import { NID } from '../../Processes/CheckInProcess/types';
import { Container } from '../../components/Container';
import generalMessages from '../messages';
import { CatchmentAreaStyles } from './SymptomCheckerStyles';
import { ButtonVariant } from '@babylon/medkit';
import { useState } from 'react';
import SymptomCheckerStart from './SymptomCheckerStart';
import { getHealthCentreId } from '../../redux/HealthCentre/getHealthCentreId';
import { useSelector } from 'react-redux';

type SymptomCheckerProps = {
	onClick?: () => void;
	patientId: null | number;
	patientNid: null | NID;
};

type PageState = 'askingToUseSymptomChecker' | 'redirectToSymptomChecker';

export const SymptomCheckerScreen = ({ onClick, patientId, patientNid }: SymptomCheckerProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const healthCentreId = useSelector(getHealthCentreId);
	const [currentPageState, setCurrentPageState] = useState<PageState>('askingToUseSymptomChecker');

	if (currentPageState === 'askingToUseSymptomChecker') {
		return (
			<Container>
				<CatchmentAreaStyles.CatchmentAreaHeader tag="h2" variant="h2">
					{formatMessage(generalMessages.symptomCheckerQuestion)}
				</CatchmentAreaStyles.CatchmentAreaHeader>
				<CatchmentAreaStyles.ButtonsContainer>
					<CatchmentAreaStyles.NoButton variant={ButtonVariant.secondary} onClick={onClick}>
						{formatMessage(generalMessages.no)}
					</CatchmentAreaStyles.NoButton>
					<CatchmentAreaStyles.YesButton onClick={() => setCurrentPageState('redirectToSymptomChecker')}>
						{formatMessage(generalMessages.yes)}
					</CatchmentAreaStyles.YesButton>
				</CatchmentAreaStyles.ButtonsContainer>
			</Container>
		);
	} else {
		return <SymptomCheckerStart {...{ healthCentreId, patientId, patientNid }} />;
	}
};
