import styled from 'styled-components';
import { ButtonSimple, Input } from '@babylon/medkit';

const Button = styled(ButtonSimple)`
	margin: 60px 0 0 0;
`;

const Container = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: auto;
	width: 300px;
`;

const StyledInput = styled(Input)`
	margin: 55px 0 5px 0;
`;

const Logo = styled.img`
	width: 160px;
	align-self: center;
`;

const VersionNumber = styled.div`
	opacity: 0.5;
	padding: 5px;
	position: absolute;
`;

export const HCLoginPageStyles = {
	Button,
	Container,
	StyledInput,
	Logo,
	VersionNumber,
};
