import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ModalProvider } from '@babylon/medkit';
import { CookiesProvider } from 'react-cookie';

import { HttpErrorFallback } from './components/HttpErrorFallback/HttpErrorFallback';
import { store } from './redux/store';
import AppRouter from './Router';
import './App.css';

function App(): JSX.Element {
	return (
		<ErrorBoundary FallbackComponent={HttpErrorFallback}>
			<CookiesProvider>
				<Provider store={store}>
					<BrowserRouter>
						<ModalProvider closeButtonText="">
							<AppRouter />
						</ModalProvider>
					</BrowserRouter>
				</Provider>
			</CookiesProvider>
		</ErrorBoundary>
	);
}

export default App;
