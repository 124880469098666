import { SymptomCheckerCompletedPath } from '../../Router';
import createUrl from '../../utils/createUrl';
import { encryptString } from '../../utils/encryption';
import { mockedResponses } from './mockedResponses';

export default function MockedSymptomChecker(): JSX.Element {
	return (
		<div style={{ padding: 50, width: 580 }}>
			<h1>Mocked Symptom Checker</h1>
			<hr />
			<p>This page allows you to easily mock Symptom Checker responses.</p>
			<p>
				To Disable it, comment out <b>REACT_APP_MOCK_API_REQUESTS=TRUE</b> in your <b>.env.development.local</b> file.
			</p>
			<br />
			<h2>Responses</h2>
			<hr />
			<ul>
				{mockedResponses.map((response, index) => (
					<li key={index}>
						<a
							href={createUrl(SymptomCheckerCompletedPath, {
								conversationId: response.response.conversationId,
								encryptedNid: encryptString('mockedNid'),
								encryptedHealthCentreId: encryptString('mockedHealthCentreId'),
								status: response.status,
							})}
						>
							{response.name}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
