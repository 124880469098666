import axios, { AxiosResponse } from 'axios';

import { NID, RegistrationData } from '../../Processes/CheckInProcess/types';
import { PostCheckPolicyResponse } from '../../types/contracts';
import { extractNid } from '../../utils/extractNid';

const checkinApiUrl = process.env.REACT_APP_DOMAIN + '/api/health_centres/patients';

export const postInsuranceCheck = async (
	registrationDetails: RegistrationData,
	hohNid: NID,
	token: string
): Promise<AxiosResponse<PostCheckPolicyResponse>> =>
	await axios.post(
		`${checkinApiUrl}/validate_insurance`,
		{
			registration: {
				dob: registrationDetails.dateOfBirth,
				firstName: registrationDetails.firstName,
				id: registrationDetails.identityUrn,
				lastName: registrationDetails.lastName,
				nationalId: extractNid(registrationDetails.identifier),
			},
			system: `urn:babylon:rwa:org:mutuelle:policy:${hohNid || extractNid(registrationDetails.identifier)}`,
		},
		{
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			responseType: 'json',
		}
	);
