import { Text } from '@babylon/medkit';
import { useIntl } from 'react-intl';

import { Container } from '../../components/Container';
import { Notification } from '../../components/Notification';
import generalMessages from '../messages';

import { ErrorScreenStyles } from './ErrorScreen.styles';
import errorMessages from './messages';

type ErrorScreenProps = {
	error?: string;
	onClick?: () => void;
};

export const ErrorScreen = ({ error, onClick }: ErrorScreenProps): JSX.Element => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			<ErrorScreenStyles.Container>
				<ErrorScreenStyles.TitleWrapper>
					<ErrorScreenStyles.ErrorIcon />
					<ErrorScreenStyles.Message variant="body">{error}</ErrorScreenStyles.Message>
				</ErrorScreenStyles.TitleWrapper>
				<Notification title={formatMessage(errorMessages.next)}>
					<Text variant="body">{formatMessage(errorMessages.nextInstructions)}</Text>
				</Notification>
				<ErrorScreenStyles.OkButton onClick={onClick}>{formatMessage(generalMessages.ok)}</ErrorScreenStyles.OkButton>
			</ErrorScreenStyles.Container>
		</Container>
	);
};
