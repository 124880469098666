import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoginResult, StaffCredentials } from '../../Processes/HCLoginProcess/types';
import { LoginError } from '../../Processes/CheckInProcess/types';
import { postLoginService } from '../../api/apiCalls/postLogin';
import { mockLoginGeneralError } from '../../api/mockApi/mocks';
import { setSession } from '../../Processes/session';
import { getLoginError, isAxiosError } from '../axiosError';

export const staffLogin = createAsyncThunk<LoginResult, StaffCredentials, { rejectValue: LoginError }>(
	'login',
	async (credentials: StaffCredentials, thunkApi) => {
		try {
			const response = await postLoginService(credentials);
			setSession(response);
			return response;
		} catch (error) {
			if (isAxiosError(error)) {
				const appError = getLoginError(error);
				return thunkApi.rejectWithValue(appError);
			}
		}
		return thunkApi.rejectWithValue(mockLoginGeneralError);
	}
);
