import styled from 'styled-components';

import { colors } from '../../Views/colors';
import { InfoIcon } from './components/InfoIcon/InfoIcon';

const NotificationWrapper = styled.div`
	border: 4px solid ${colors.highlight};
	border-radius: 0.5rem;
	max-width: 22rem;
	padding: 1rem 2rem 0 1rem;
	margin-bottom: 1.5rem;
`;

const TitleWrapper = styled.div`
	align-items: center;
	display: inline-flex;
`;

const Icon = styled(InfoIcon)`
	margin-right: 1rem;
`;

const ContentWrapper = styled.div`
	margin-bottom: 1.5rem;
	margin-left: 2.6rem;
	margin-top: 0.75rem;
`;

export const NotificationStyles = {
	ContentWrapper,
	NotificationWrapper,
	TitleWrapper,
	Icon,
};
