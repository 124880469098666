import { useState } from 'react';
import { KBBWindow } from '../../types/printer';
import './DebugPrinterWidget.css';

declare let window: KBBWindow;

export default function DebugPrinterWidget(): JSX.Element {
	const [enableWidget, setEnableWidget] = useState(false);
	const [loading, _setLoading] = useState(false);
	const setLoading = () => {
		_setLoading(true);
		setTimeout(() => _setLoading(false), 3000);
	};

	const printer = window?.KBBluetooth;

	const handleConnectPrinter = () => {
		setLoading();
		printer.connect();
	};
	const handleDisonnectPrinter = () => {
		setLoading();
		printer.disconnect();
	};
	const handleBluetoothEnabled = (enabled: boolean) => {
		setLoading();
		printer.setBluetoothEnabled(enabled);
	};

	const handlePrint = () => {
		setLoading();
		if (printer.isConnected()) {
			const printLine = (line: string) => {
				const result = printer.sendBytes(line);
				return result !== false;
			};
			const lines = [
				'\x1B\x40', // reset
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|              Printer Connected!              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'|                                              |\n',
				'\n\n\n',
				'\x1D\x56\x42\x00', //cut
			];
			const results = lines.map(printLine);
			if (!results.every((line) => line)) {
				alert('Print Error');
			}
		} else {
			alert('Printer Not Connected');
		}
	};

	function WidgetContent(): JSX.Element {
		if (loading) {
			return <p>Loading...</p>;
		} else if (!enableWidget) {
			return (
				<button className="debugPrinterButton" onClick={() => setEnableWidget(true)}>
					🖨
				</button>
			);
		} else {
			return (
				<>
					<div className="flexItem">
						<h3>🖨 Printer</h3>
						<button onClick={handleConnectPrinter}>Connect</button>
						<button onClick={handleDisonnectPrinter}>Disconnect</button>
						<button onClick={handlePrint}>Test</button>
					</div>
					<div className="flexItem">
						<h3>📶 Bluetooth</h3>
						<button onClick={() => handleBluetoothEnabled(true)}>Enable</button>
						<button onClick={() => handleBluetoothEnabled(false)}>Disable</button>
					</div>
					<div className="flexItem">
						<button className="exitButton" onClick={() => setEnableWidget(false)}>
							❌
						</button>
					</div>
				</>
			);
		}
	}

	return (
		<div className={`widget ${enableWidget && 'active'}`}>
			<WidgetContent />
		</div>
	);
}
