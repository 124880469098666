import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ButtonVariant } from '@babylon/medkit';

import { catchmentAreaActions } from '../../redux/CatchmentArea/catchmentArea.slice';
import { Container } from '../../components/Container';
import generalMessages from '../messages';

import { CatchmentAreaStyles } from './CatchmentAreaStyles';

type CatchmentAreaPageProps = {
	onAnswer: () => void;
};

export const CatchmentAreaPage = ({ onAnswer }: CatchmentAreaPageProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const approveCatchmentArea = () => {
		dispatch(catchmentAreaActions.approve());
		onAnswer();
	};
	const declineCatchmentArea = () => {
		dispatch(catchmentAreaActions.decline());
		onAnswer();
	};

	return (
		<Container>
			<CatchmentAreaStyles.CatchmentAreaHeader tag="h2" variant="h2">
				{formatMessage(generalMessages.catchmentAreaQuestion)}
			</CatchmentAreaStyles.CatchmentAreaHeader>
			<CatchmentAreaStyles.ButtonsContainer>
				<CatchmentAreaStyles.NoButton variant={ButtonVariant.secondary} onClick={() => declineCatchmentArea()}>
					{formatMessage(generalMessages.no)}
				</CatchmentAreaStyles.NoButton>
				<CatchmentAreaStyles.YesButton onClick={() => approveCatchmentArea()}>
					{formatMessage(generalMessages.yes)}
				</CatchmentAreaStyles.YesButton>
			</CatchmentAreaStyles.ButtonsContainer>
		</Container>
	);
};
