import { defineMessages } from 'react-intl';

export default defineMessages({
	consent: {
		id: 'IdentityCardScanner.consent',
		defaultMessage: `By scanning your ID card, you agree to Babyl using your data exclusively for the purpose of
		checking the validity of your insurance. This data will be stored by Babyl and can be deleted upon request.`,
	},
	callToAction: {
		id: 'IdentityCardScanner.callToAction',
		defaultMessage: 'Validate your insurance',
	},
	fallbackOption: {
		id: 'IdentityCardScanner.fallbackOption',
		defaultMessage: "It didn't work",
	},
	stepOne: {
		id: 'IdentityCardScanner.stepOne',
		defaultMessage: 'Hold your ID card to the camera',
	},
	stepTwo: {
		id: 'IdentityCardScanner.stepTwo',
		defaultMessage: 'Show the back of the card',
	},
	stepThree: {
		id: 'IdentityCardScanner.stepThree',
		defaultMessage: 'Move the card into the target area',
	},
});
