import { createSlice } from '@reduxjs/toolkit';

import { CatchmentAreaState } from '../../Processes/CheckInProcess/types';
import { RootState } from '../store';

export const BASENAME = 'catchmentArea';

const initialState: CatchmentAreaState = {
	isFromCatchmentArea: false,
};

const catchmentAreaSlice = createSlice({
	name: BASENAME,
	initialState,
	reducers: {
		setDefault: () => initialState,
		approve: () => ({ isFromCatchmentArea: true }),
		decline: () => ({ isFromCatchmentArea: false }),
	},
});

export const selectIsAccepted = (state: RootState): boolean => state.catchmentArea.isFromCatchmentArea;

export const { reducer, actions: catchmentAreaActions } = catchmentAreaSlice;
