import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { isAxiosError } from '../axiosError';
import { postTimeLog } from '../../api/apiCalls/postTimeLog';
import { HCAuthorizationProcessState } from '../../Processes/HCLoginProcess/types';

export const sendTimeLog = createAsyncThunk<
	number,
	HCAuthorizationProcessState,
	{ state: RootState; rejectValue: number }
>(`timeLog/sendTimeLog`, async (hc: HCAuthorizationProcessState, thunkAPI) => {
	const { timeLog } = thunkAPI.getState();
	try {
		if (hc.step === 'HC_LOGIN_SUCCESSFUL') {
			const timeLogResponse = await postTimeLog(timeLog, hc.token, hc.healthCentreId, hc.healthCentreName);
			return timeLogResponse.status;
		}
		return 400;
	} catch (error) {
		if (isAxiosError(error)) {
			return error?.response?.status || 404;
		}
		return 500;
	}
});
