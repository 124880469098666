import { createSlice } from '@reduxjs/toolkit';
import { Languages } from '../../types/enums';

import { RootState } from '../store';

export const BASENAME = 'locale';

const initialState: { locale: string } = {
	locale: Languages.EN,
};

const localeSlice = createSlice({
	name: BASENAME,
	initialState,
	reducers: {
		setLocale: (_, { payload }) => ({ locale: payload }),
	},
});

export const selectLocale = (state: RootState): string => state.locale.locale;

export const { reducer } = localeSlice;
export const { setLocale } = localeSlice.actions;
