import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { RootState } from './redux/store';
import { PatientDetails } from './Views/PatientDetails';
import { ErrorScreen } from './Views/ErrorScreen';
import { HouseholdDetails } from './Views/HouseholdDetails';
import { HCLoginProcess } from './Processes/HCLoginProcess';
import { CheckInProcess } from './Processes/CheckInProcess';
import { translations } from './translations';
import SymptomCheckerCompleted from './Views/SymptomCheckerScreen/SymptomCheckerCompleted';
import SymptomCheckerStart from './Views/SymptomCheckerScreen/SymptomCheckerStart';
import { getHealthCentreId } from './redux/HealthCentre/getHealthCentreId';

export const SymptomCheckerStartPath = '/SymptomCheckerStart';
export const SymptomCheckerCompletedPath = '/SymptomCheckerCompleted';

const AppRouter = (): JSX.Element => {
	const { locale } = useSelector((state: RootState) => state.locale);

	return (
		<Switch>
			<Route exact path="/">
				<HCLoginProcess />
			</Route>
			<IntlProvider locale={locale} messages={translations[locale]}>
				<Route path="/register">
					<CheckInProcess />
				</Route>
				<Route path="/patient-details">
					<PatientDetails />
				</Route>
				<Route path="/household-details">
					<HouseholdDetails />
				</Route>
				<Route path="/errors">
					<ErrorScreen error="Sorry. Your Mutuelle policy expired." />
				</Route>
				<Route path={SymptomCheckerStartPath}>
					<SymptomCheckerStart
						{...{
							healthCentreId: useSelector(getHealthCentreId),
							patientId: null,
							patientNid: null,
						}}
					/>
				</Route>
				<Route path={SymptomCheckerCompletedPath}>
					<SymptomCheckerCompleted />
				</Route>
			</IntlProvider>
		</Switch>
	);
};

export default AppRouter;
