import styled from 'styled-components';
import { ButtonSimple, Text } from '@babylon/medkit';

import { CheckIcon } from './components/CheckIcon';

import { ExclamationIcon } from '../../components/ExclamationIcon';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: auto;
	margin-top: auto;
`;

const NextButton = styled(ButtonSimple)`
	width: 18rem;
`;

const TryAgainButton = styled(ButtonSimple)`
	width: 18rem;
`;

const CheckmarkIcon = styled(CheckIcon)`
	margin-right: 0.5rem;
`;

const PrinterIcon = styled.img`
	height: auto;
	margin-left: calc(36px + 0.5rem);
	width: 10rem;
`;

const ErrorIcon = styled(ExclamationIcon)`
	margin-right: 0.5rem;
`;

const TitleWrapper = styled.div`
	align-items: center;
	display: inline-flex;
	margin-bottom: 3rem;
	margin-top: 1rem;
`;

const Message = styled(Text)`
	font-size: 1.75rem;
	font-weight: 300;
`;

export const CheckinConfirmationStyles = {
	Container,
	CheckmarkIcon,
	ErrorIcon,
	NextButton,
	TryAgainButton,
	Message,
	PrinterIcon,
	TitleWrapper,
};
