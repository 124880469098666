import { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>): JSX.Element => (
	<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M9.707 17.7073C10.098 17.3162 10.098 16.6842 9.707 16.2932L3.414 10.0002H19C19.552 10.0002 20 9.55225 20 9.00025C20 8.44825 19.552 8.00025 19 8.00025H3.414L9.707 1.70725C10.098 1.31625 10.098 0.68425 9.707 0.29325C9.316 -0.09775 8.684 -0.09775 8.293 0.29325L0.293 8.29325C0.098 8.48825 0 8.74425 0 9.00025C0 9.25625 0.098 9.51225 0.293 9.70725L8.293 17.7073C8.684 18.0983 9.316 18.0983 9.707 17.7073Z"
			fill="#400099"
		/>
	</svg>
);
