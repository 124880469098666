import styled from 'styled-components';
import { Text, ButtonSimple, Checkbox, LinkButton } from '@babylon/medkit';

const BackendError = styled.div`
	margin-bottom: 1.25rem;
`;

const ConsentCheckbox = styled(Checkbox)`
	font-size: 0.75rem;
	line-height: 1.5rem;

	& > div {
		padding: 0;
	}
`;

const Container = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	width: 19rem;
	align-items: center;
`;

const ContinueButton = styled(ButtonSimple)`
	width: 100%;
	margin: 1.5rem 0;
`;

const CloseButton = styled(ButtonSimple)`
	width: 19rem;
	margin-top: 2.5rem;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const FormElement = styled.div`
	margin-top: 2.5rem;
`;

const FormContainer = styled.div`
	width: 100%;
`;

const Header = styled(Text)`
	margin-bottom: 1.25rem;
`;

const LearnMoreButton = styled(LinkButton)`
	font-size: 1.2rem;
`;

export const PatientDetailsStyles = {
	BackendError,
	ConsentCheckbox,
	Container,
	ContinueButton,
	CloseButton,
	CloseButtonContainer,
	FormElement,
	FormContainer,
	Header,
	LearnMoreButton,
};
