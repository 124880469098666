import { ReactNode } from 'react';
import { Text } from '@babylon/medkit';

import { NotificationStyles } from './Notification.styles';

type NextStepInfoProps = {
	title: string;
	children: ReactNode;
};

export const Notification = ({ title, children }: NextStepInfoProps): JSX.Element => {
	return (
		<NotificationStyles.NotificationWrapper>
			<NotificationStyles.TitleWrapper>
				<NotificationStyles.Icon />
				<Text variant="h5">{title}</Text>
			</NotificationStyles.TitleWrapper>
			<NotificationStyles.ContentWrapper>{children}</NotificationStyles.ContentWrapper>
		</NotificationStyles.NotificationWrapper>
	);
};
