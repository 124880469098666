import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { TimeLogState, Timestamp } from '../../Processes/CheckInProcess/types';
import { sendTimeLog } from './sendTimeLog';

export const BASENAME = 'timeLog';

const defaultTimeLog = {
	events: [],
};

const initialState: TimeLogState = defaultTimeLog;

const timeLogSlice = createSlice({
	name: BASENAME,
	initialState,
	reducers: {
		initSession: (state) => {
			state.session = uuidv4();
		},
		pushEvent: (state, { payload }: { payload: Timestamp }) => {
			const event = state.events.find((e) => e.event_name == payload.event_name);
			if (event) {
				const index = state.events.indexOf(event);
				state.events[index] = payload;
			} else {
				state.events.push({ ...payload });
			}
		},
		clearEvents: (state) => {
			state.events = [];
		},
		clearTimeLog: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(sendTimeLog.rejected, (state) => {
			state.events = [];
		});
		builder.addCase(sendTimeLog.fulfilled, (state) => {
			state.events = [];
		});
	},
});
export const { reducer, actions: timeLogActions } = timeLogSlice;
