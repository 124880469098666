import styled from 'styled-components';
import { ButtonSimple, Text } from '@babylon/medkit';

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const CatchmentAreaHeader = styled(Text)`
	font-size: 1.75rem;
	font-weight: 300;
`;

const NoButton = styled(ButtonSimple)`
	margin-top: 5rem;
	width: 18rem;
`;

const YesButton = styled(ButtonSimple)`
	margin-top: 5rem;
	width: 18rem;
`;

export const CatchmentAreaStyles = {
	ButtonsContainer,
	CatchmentAreaHeader,
	NoButton,
	YesButton,
};
