import { ResponseComposition, ResponseFunction, rest, RestContext, RestRequest } from 'msw';

import { HcLoginResponse, LoginBody } from '../../Processes/HCLoginProcess/types';
import {
	PostRegistrationResponse,
	PostRegistrationBody,
	PostInsuranceCheckBody,
	PostCheckPolicyResponse,
	PostTimeLogBody,
} from '../../types/contracts';
import { AppError, LoginError } from '../../Processes/CheckInProcess/types';
import { tokenExpirationSeconds } from '../../Processes/session';

import {
	mockPolicyResponse,
	mockPolicyExpiredError,
	mockPolicyWeNeedMoreInformationError,
	mockNidNotFoundResponse,
	infrastructureError,
	mockRegistrationSuccessResponse,
	mockLoginError,
	mockPolicyNotFoundError,
	mockLoginSuccess,
} from './mocks';

const hcApiUrl = process.env.REACT_APP_DOMAIN + '/api/v1';
const checkinApiUrl = process.env.REACT_APP_DOMAIN + '/api/health_centres/patients';
const timeLogApiUrl = process.env.REACT_APP_DOMAIN + '/api/health_centres/events';

const handleSignIn = (reqBody: LoginBody, res: ResponseComposition<HcLoginResponse | LoginError>, ctx: RestContext) => {
	const { email, password } = reqBody;
	if (email === 'test@test.com' && password === 'test') {
		return res(
			ctx.delay(),
			ctx.status(201),
			ctx.set({
				'x-access-token': '12ab34567890123c1d45e6fghi78jk90',
				'x-access-token-expires': `${tokenExpirationSeconds}`,
			}),
			ctx.json(mockLoginSuccess)
		);
	} else {
		return res(ctx.delay(), ctx.status(422), ctx.json(mockLoginError));
	}
};

const handleRegistrationResponses = (
	reqBody: PostRegistrationBody,
	res: ResponseComposition<PostRegistrationResponse | AppError>,
	ctx: RestContext
) => {
	const { identifier } = reqBody;
	if (identifier.includes('404')) return res(ctx.delay(), ctx.status(404), ctx.json(mockNidNotFoundResponse));
	if (identifier.includes('500')) return res(ctx.delay(), ctx.status(500), ctx.json(infrastructureError));

	return res(ctx.delay(), ctx.status(200), ctx.json(mockRegistrationSuccessResponse));
};

const handleInsuranceCheckResponses = (
	reqBody: PostInsuranceCheckBody,
	res: ResponseComposition<PostCheckPolicyResponse | AppError>,
	ctx: RestContext
) => {
	const { system } = reqBody;

	if (system.includes('400')) return res(ctx.delay(), ctx.status(400), ctx.json(mockPolicyWeNeedMoreInformationError));
	if (system.includes('402')) return res(ctx.delay(), ctx.status(402), ctx.json(mockPolicyExpiredError));
	if (system.includes('44')) return res(ctx.delay(), ctx.status(404), ctx.json(mockPolicyNotFoundError));
	if (system.includes('55')) return res(ctx.delay(), ctx.status(500), ctx.json(infrastructureError));

	return res(ctx.delay(), ctx.status(200), ctx.json(mockPolicyResponse()));
};

const handleTimeLogResponses = (reqBody: PostTimeLogBody, res: ResponseFunction, ctx: RestContext) => {
	return res(ctx.delay(), ctx.status(200));
};

export const handlers = [
	rest.post(`${hcApiUrl}/sessions`, (req: RestRequest<LoginBody>, res, ctx) => handleSignIn(req.body, res, ctx)),

	rest.post(`${checkinApiUrl}/register`, (req: RestRequest<PostRegistrationBody>, res, ctx) =>
		handleRegistrationResponses(req.body, res, ctx)
	),

	rest.post(
		`${checkinApiUrl}/validate_insurance`,
		(req: RestRequest<PostInsuranceCheckBody>, res: ResponseComposition<PostCheckPolicyResponse | AppError>, ctx) =>
			handleInsuranceCheckResponses(req.body, res, ctx)
	),

	rest.post(`${timeLogApiUrl}`, (req: RestRequest<PostTimeLogBody>, res: ResponseFunction, ctx) =>
		handleTimeLogResponses(req.body, res, ctx)
	),
];
