import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { Cookies } from 'react-cookie';
import { Spinner } from '../../components/Spinner';
import { mockedResponses } from './mockedResponses';
import { handleRestartRegistration } from '../../Processes/CheckInProcess/CheckInProcessActions';
import {
	SymptomCheckerConversationAttachment as ConversationAttachment,
	SymptomCheckerConversationMessage as ConversationMessage,
	SymptomCheckerConversationResponse as ConversationResponse,
	NID,
	TriageOutcomeConversation,
	TriageOutcomeConversationHealthCentreIdFallback,
	TriageOutcomeConversationPatientNidFallback,
	TriageOutcomeData,
} from '../../Processes/CheckInProcess/types';
import { hcAuthToken } from '../../Processes/session';
import { decryptString } from '../../utils/encryption';
import { PrintTicket } from '../PrintTicket/PrintTicket';
import generalMessages from '../../Views/messages';
import { Container } from '../../components/Container';
import { CatchmentAreaStyles } from './SymptomCheckerStyles';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale, setLocale } from '../../redux/Locale/locale.slice';
import { SymptomCheckerCompletedStatus } from '../../types/enums';

const cookies = new Cookies();
const { REACT_APP_DOMAIN, REACT_APP_MOCK_API_REQUESTS } = process.env;
const MOCK_API_REQUESTS = REACT_APP_MOCK_API_REQUESTS === 'TRUE';

export default function SymptomCheckerCompleted(): JSX.Element {
	const queryParams = new URLSearchParams(window.location.search);
	const encryptedNid: string | null = queryParams.get('encryptedNid');
	const encryptedHealthCentreId: string | null = queryParams.get('encryptedHealthCentreId');
	const conversationId: string | null = queryParams.get('conversationId');
	const patientNid: NID | null = encryptedNid ? decryptString(encryptedNid) : null;
	const healthCentreId: string | null = encryptedHealthCentreId ? decryptString(encryptedHealthCentreId) : null;
	const locale: string | null = queryParams.get('locale');
	const status: string | null = queryParams.get('status');

	const [{ data, loading, error }, execute] = useAxios(
		{
			baseURL: REACT_APP_DOMAIN,
			url: `/clinical_portal/api/v1/conversations/${conversationId}`,
			headers: { Authorization: cookies.get(hcAuthToken) },
			// headers: { Authorization: 'aa2f26a8909f3d03c98db3ecbf173a1d' },
		},
		{ manual: true }
	);

	const { formatMessage } = useIntl();
	const currentLocale = useSelector(selectLocale);
	const dispatch = useDispatch();
	useEffect(() => {
		if (locale && locale !== currentLocale) {
			dispatch(setLocale(locale));
		}
		if (!MOCK_API_REQUESTS) {
			execute();
		}
	}, []);

	const ErrorPage = (errorMessage: { id: string; defaultMessage: string }) => (
		<Container>
			<CatchmentAreaStyles.CatchmentAreaHeader tag="h2" variant="h2">
				{formatMessage(errorMessage)}
			</CatchmentAreaStyles.CatchmentAreaHeader>
			<CatchmentAreaStyles.ButtonsContainer>
				<CatchmentAreaStyles.YesButton onClick={handleRestartRegistration}>
					{formatMessage(generalMessages.ok)}
				</CatchmentAreaStyles.YesButton>
			</CatchmentAreaStyles.ButtonsContainer>
		</Container>
	);

	if (status === SymptomCheckerCompletedStatus.EXITED) {
		return ErrorPage(generalMessages.symptomCheckerExitedByUser);
	} else if (error) {
		return ErrorPage(generalMessages.symptomCheckerUnexpectedError);
	} else if (loading || !data) {
		return <Spinner />;
	}

	const apiResponse = MOCK_API_REQUESTS
		? mockedResponses
				.filter((x) => x.response.conversationId === conversationId)
				.map((x) => x.response)
				.slice(-1)[0]
		: data;

	const parseApiResponse = (conversation: ConversationResponse) => {
		const conversationAttachmentBody = JSON.parse(conversation.conversationAttachment.body) as ConversationAttachment;
		const { status, triage, positive_symptoms, failure_reason } = conversationAttachmentBody;
		const { sex, age, completed_at } = conversationAttachmentBody.case;
		const getFirstUserAnswer = () => {
			const messages = conversation.messages.map((message) => JSON.parse(message.payload));
			const userMessages = messages.filter((message: ConversationMessage) => message.sender === 'user');
			if (userMessages) {
				const lastUserMessage = userMessages.slice(-1)[0];
				return lastUserMessage.value.text.member_translation;
			} else {
				return 'ERROR';
			}
		};
		const getTriageOutcome = (() => {
			if (status === 'failed') {
				return failure_reason.message;
			} else if (triage) {
				return triage.title;
			} else {
				return formatMessage(generalMessages.symptomCheckerOutcomeUnknown);
			}
		})();
		return {
			id: conversation.conversationId,
			patientNid: patientNid || TriageOutcomeConversationPatientNidFallback,
			healthCentreId: healthCentreId || TriageOutcomeConversationHealthCentreIdFallback,
			sex,
			age,
			completedAt: completed_at.split('T')[0],
			triageOutcome: getTriageOutcome,
			positiveSymptoms: positive_symptoms.map((symptom) => symptom.layman_name),
			initialInput: getFirstUserAnswer(),
		};
	};

	const conversation = parseApiResponse(apiResponse);

	const triageOutcomeData: TriageOutcomeData = { conversation };
	return (
		<PrintTicket
			nextButtonLabel={generalMessages.finish}
			onClick={handleRestartRegistration}
			data={triageOutcomeData}
		/>
	);
}
