import {
	CheckinConfirmationData,
	TriageOutcomeData,
	PatientCheckinData,
} from '../../../Processes/CheckInProcess/types';
import { extractNid } from '../../../utils/extractNid';
import { translations } from '../../../translations';
import { getSessionStorageData } from '../../../Processes/session';

const ticketPrefix = 'Ticket';
const generalPrefix = 'General';
const en = translations['en-RW'];
const rw = translations['rw-RW'];

const RESET = '\x1B\x40';
const EMPHASIZE_ON = '\x1B\x45\x01';
const EMPHASIZE_OFF = '\x1B\x45\x02';
const LEFT_JUSTIFIED = '\x1B\x61\x00';
const CENTER_JUSTIFIED = '\x1B\x61\x01';
const DOUBLE_HEIGHT_ON = '\x1B\x21\x10';
const DOUBLE_HEIGHT_OFF = '\x1B\x21\x00';
const NEW_LINE = '\x0A';
const HR = '------------------------------------------------\n';
const CUT = '\x1D\x56\x42\x00';

const getTranslated = (obj: Record<string, string>, key: string, prefix = ticketPrefix) => {
	const keyWithBase = `${prefix}.${key}`;
	return obj[keyWithBase];
};

const withPrefix = (prefix: string, content: string) => `${prefix}${content}`;

const titleWithText = (title: string, content: string | string[]) => {
	const linesOfText = typeof content === 'string' ? [content] : content;
	const formattedText = linesOfText.map((text) => text + '\n');
	return [EMPHASIZE_ON, title, '\n', EMPHASIZE_OFF, ...formattedText];
};

const wordWrapText = (inputString: string) => {
	// by default, the printer chops multi-line messages by character
	// rather than word, which makes the ux confusing as the ticket has
	// words chopped off half-way. Instead we should wrap text on words
	const maxCharsPerRow = 49;
	const lines: string[] = [];
	inputString.split(' ').forEach((word) => {
		const lastLineNumber = lines.length - 1;
		if (lastLineNumber >= 0) {
			const updatedLastLine = [lines[lastLineNumber], word].join(' ');
			if (updatedLastLine.length < maxCharsPerRow) {
				lines[lastLineNumber] = updatedLastLine;
			} else {
				lines.push(word);
			}
		} else {
			lines.push(word);
		}
	});
	return lines.join('\n');
};

export const getTicketContent = (data: CheckinConfirmationData | TriageOutcomeData): string[] => {
	const isCheckinConfirmationData = 'dateOfToday' in data;
	if (isCheckinConfirmationData) {
		const ticketText = {
			checkInTicketTitle: `${getTranslated(en, 'checkInTicketTitle')} / ${getTranslated(rw, 'checkInTicketTitle')}`,
			instruction: `${getTranslated(en, 'instruction')} / ${getTranslated(rw, 'instruction')}`,
			dateOfToday: `${getTranslated(en, 'dateOfToday')} / ${getTranslated(rw, 'dateOfToday')}`,
			name: `${getTranslated(en, 'name')} / ${getTranslated(rw, 'name')}`,
			patientNid: `${getTranslated(en, 'patientNid')} / ${getTranslated(rw, 'patientNid')}`,
			insuranceProvider: `${getTranslated(en, 'insuranceProvider')} / ${getTranslated(rw, 'insuranceProvider')}`,
			hohNid: `${getTranslated(en, 'hohNid')} / ${getTranslated(rw, 'hohNid')}`,
			hohName: `${getTranslated(en, 'hohName')} / ${getTranslated(rw, 'hohName')}`,
			forStaff: `${getTranslated(en, 'forStaff')} / ${getTranslated(rw, 'forStaff')}`,
			babylServiceEn: `${getTranslated(en, 'babylService')}`,
			babylServiceRw: `${getTranslated(rw, 'babylService')}`,
			catchmentAreaQuestion: `${getTranslated(en, 'catchmentAreaQuestion', generalPrefix)} / ${getTranslated(
				rw,
				'catchmentAreaQuestion',
				generalPrefix
			)}`,
			yes: `${getTranslated(en, 'yes', generalPrefix)} / ${getTranslated(rw, 'yes', generalPrefix)}`,
			no: `${getTranslated(en, 'no', generalPrefix)} / ${getTranslated(rw, 'no', generalPrefix)}`,
			ubudeheCategory: `${getTranslated(en, 'ubudeheCategory')} / ${getTranslated(rw, 'ubudeheCategory')}`,
		};
		const isHohPatient = (patient: PatientCheckinData) => {
			if (patient.patientNid === patient.hohNid) {
				return [
					...titleWithText(ticketText.name, patient.name),
					HR,
					...titleWithText(ticketText.patientNid, extractNid(patient.patientNid)),
				];
			} else {
				return [
					...titleWithText(ticketText.name, patient.name),
					HR,
					...titleWithText(ticketText.patientNid, extractNid(patient.patientNid)),
					HR,
					...titleWithText(ticketText.hohNid, patient.hohNid),
					HR,
					...titleWithText(ticketText.hohName, patient.hohName),
				];
			}
		};
		return [
			RESET,
			LEFT_JUSTIFIED,
			DOUBLE_HEIGHT_ON,
			EMPHASIZE_ON,
			ticketText.checkInTicketTitle,
			EMPHASIZE_OFF,
			DOUBLE_HEIGHT_OFF,
			NEW_LINE,
			NEW_LINE,
			ticketText.instruction,
			NEW_LINE,
			NEW_LINE,
			HR,
			...titleWithText(ticketText.dateOfToday, data.dateOfToday),
			HR,
			...isHohPatient(data),
			HR,
			...titleWithText(ticketText.ubudeheCategory, data.ubudeheCategory),
			HR,
			...titleWithText(ticketText.catchmentAreaQuestion, data.isFromCatchmentArea ? ticketText.yes : ticketText.no),
			HR,
			...titleWithText(ticketText.insuranceProvider, data.insuranceProvider),
			HR,
			NEW_LINE,
			CENTER_JUSTIFIED,
			ticketText.babylServiceEn,
			NEW_LINE,
			NEW_LINE,
			ticketText.babylServiceRw,
			NEW_LINE,
			NEW_LINE,
			CUT,
		].map((line: string) => wordWrapText(line));
	} else {
		const getTranslatedString = (name: string) => getTranslated(en, name) + ' / ' + getTranslated(rw, name);
		const ticketText = {
			triageOutcomeTicketTitle: getTranslatedString('triageOutcomeTicketTitle'),
			dateOfToday: getTranslatedString('dateOfToday'),
			healthCentre: getTranslatedString('healthCentre'),
			patientNid: getTranslatedString('patientNid'),
			positiveSymptoms: getTranslatedString('positiveSymptoms'),
			initialInput: getTranslatedString('initialInput'),
		};
		const conversation = data.conversation;
		const heading = [
			DOUBLE_HEIGHT_ON,
			EMPHASIZE_ON,
			ticketText.triageOutcomeTicketTitle,
			EMPHASIZE_OFF,
			DOUBLE_HEIGHT_OFF,
			NEW_LINE,
		];
		const triageOutcome = [
			DOUBLE_HEIGHT_ON,
			EMPHASIZE_ON,
			conversation.triageOutcome,
			EMPHASIZE_OFF,
			DOUBLE_HEIGHT_OFF,
			NEW_LINE,
		];
		const symptomBulletPoints = conversation.positiveSymptoms.map((symptom: string) => withPrefix('- ', symptom));
		const sessionData = getSessionStorageData();
		return [
			RESET,
			LEFT_JUSTIFIED,
			...heading,
			HR,
			NEW_LINE,
			NEW_LINE,
			...triageOutcome,
			NEW_LINE,
			NEW_LINE,
			HR,
			EMPHASIZE_OFF,
			DOUBLE_HEIGHT_OFF,
			...titleWithText(ticketText.dateOfToday, conversation.completedAt),
			HR,
			...titleWithText(ticketText.healthCentre, sessionData?.healthCentreName || conversation.healthCentreId),
			HR,
			...titleWithText(ticketText.patientNid, conversation.patientNid),
			HR,
			...titleWithText(ticketText.initialInput, conversation.initialInput),
			HR,
			NEW_LINE,
			...titleWithText(ticketText.positiveSymptoms, symptomBulletPoints),
			NEW_LINE,
			LEFT_JUSTIFIED,
			CUT,
		].map((line: string) => wordWrapText(line));
	}
};
