import styled from 'styled-components';
import { ButtonSimple, Text } from '@babylon/medkit';

const LanguageSelectionGroups = styled.div`
	align-items: center;
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	justify-content: space-around;
	margin-bottom: 18vh;
	margin-top: 11vh;
`;

const SymptomCheckerButton = styled(ButtonSimple)`
	flex: 1 1 100%;
	justify-content: center;
	margin: 10px;
	width: 15rem;
	max-height: 4rem;
`;

const LanguageSelection = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Heart = styled.img`
	align-self: center;
	margin-bottom: 0.5rem;
	width: 4.5em;
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const LanguageButton = styled(ButtonSimple)`
	flex: 1 1 100%;
	justify-content: center;
	margin: 0;
	width: 18rem;
`;

const ButtonLabel = styled.span`
	text-align: center;
`;

const CallToAction = styled(Text)`
	font-size: 1.75rem;
	font-weight: 300;
	margin-bottom: 2rem;
`;

export const LanguagePageStyles = {
	LanguageSelectionGroups,
	LanguageSelection,
	Heart,
	ButtonsContainer,
	LanguageButton,
	ButtonLabel,
	SymptomCheckerButton,
	CallToAction,
};
