import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppError, PatientData, RegistrationData } from '../../Processes/CheckInProcess/types';
import { RootState } from '../store';
import { postRegister } from '../../api/apiCalls/postRegister';
import { logEvent } from '../../redux/TimeLog/eventsService';
import { getAgeRange } from '../../utils/getAgeRange';
import { getGender } from '../../utils/getGender';
import { mockCredentialsError, mockPolicyGeneralError } from '../../api/mockApi/mocks';
import { getAppError, isAxiosError } from '../axiosError';
import { fetchInsuranceDetails } from './fetchInsuranceDetails';
import { checkInActions } from './checkIn.slice';
import { AxiosResponse } from 'axios';

export const fetchRegistrationDetails = createAsyncThunk<
	RegistrationData,
	PatientData,
	{ state: RootState; rejectValue: AppError }
>(`registration/fetchRegistrationDetails`, async (patientData: PatientData, thunkAPI) => {
	const { healthCentre } = thunkAPI.getState();
	try {
		if (healthCentre.step === 'HC_LOGIN_SUCCESSFUL') {
			const registerResponse: AxiosResponse<RegistrationData> = await postRegister(patientData, healthCentre.token);
			thunkAPI.dispatch(checkInActions.setPatientRegistrationDataStep(registerResponse.data));
			thunkAPI.dispatch(logEvent('setRegisterResult', `${registerResponse.status}`));
			thunkAPI.dispatch(logEvent('setAgeRange', getAgeRange(registerResponse.data.dateOfBirth)));
			thunkAPI.dispatch(logEvent('setGender', getGender(registerResponse.data.identifier)));
			thunkAPI.dispatch(fetchInsuranceDetails(patientData.patientNID));
			return registerResponse.data;
		}
		return thunkAPI.rejectWithValue(mockCredentialsError);
	} catch (error) {
		if (isAxiosError(error)) {
			const appError = getAppError(error);
			thunkAPI.dispatch(logEvent('setRegisterResult', `${appError.status ? appError.status : 500}`));
			return thunkAPI.rejectWithValue(appError);
		}
		return thunkAPI.rejectWithValue(mockPolicyGeneralError);
	}
});
