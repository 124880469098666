import { SymptomCheckerConversationResponse as ConversationResponse } from '../../Processes/CheckInProcess/types';
import { SymptomCheckerCompletedStatus } from '../../types/enums';

type props = {
	name: string;
	status: SymptomCheckerCompletedStatus;
	response: ConversationResponse | { conversationId: string };
}[];

export const mockedResponses: props = [
	{
		name: 'Cough',
		status: SymptomCheckerCompletedStatus.COMPLETED,

		response: {
			conversationId: 'mocked-conversation-2',
			messages: [
				{
					payload: JSON.stringify({
						sender: 'user',
						value: {
							text: { member_translation: 'Cough' },
						},
					}),
				},
			],
			conversationAttachment: {
				body: JSON.stringify({
					case: {
						age: 22,
						completed_at: '2022-07-28T11:20:11.746254+00:00',
						sex: 'male',
					},
					positive_symptoms: [
						{ layman_name: 'Cough' },
						{ layman_name: 'Coughing up phlegm' },
						{ layman_name: 'Coughing up pus-like mucus' },
						{ layman_name: 'Runny nose' },
						{ layman_name: 'Sore throat' },
						{ layman_name: 'Fever' },
					],
					status: 'succeeded',
					triage: {
						title: 'Call Babyl or go to a health facility within 6 hours.',
					},
				}),
			},
		},
	},
	{
		name: 'Early exit due to pregnancy',
		status: SymptomCheckerCompletedStatus.COMPLETED,
		response: {
			conversationId: 'mocked-conversation-1',
			messages: [
				{
					payload: JSON.stringify({
						sender: 'user',
						value: {
							text: { member_translation: 'Cough' },
						},
					}),
				},
			],
			conversationAttachment: {
				body: JSON.stringify({
					case: {
						age: 22,
						completed_at: '2022-07-28T11:20:11.746254+00:00',
						sex: 'male',
					},
					positive_symptoms: [
						{ layman_name: 'Dizziness' },
						{ layman_name: 'Vertigo (sensation of room spinning)' },
						{ layman_name: 'Dizziness when in certain positions' },
						{ layman_name: 'Confirmed current pregnancy' },
					],
					status: 'failed',
					failure_reason: {
						message:
							"Babyl doesn't currently offer pregnancy care. If there is a chance you might be pregnant, talk to your doctor or midwife about any symptoms you have. If you feel very unwell or think it might be urgent, go to the nearest hospital or maternity unit.",
					},
				}),
			},
		},
	},
	{
		name: 'Conversation exited early',
		status: SymptomCheckerCompletedStatus.EXITED,
		response: {
			conversationId: 'mocked-conversation-3',
		},
	},
];
