import { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonVariant, List } from '@babylon/medkit';

import { Container } from '../../components/Container';
import { Notification } from '../../components/Notification';
import { CheckinConfirmationData, TriageOutcomeData } from '../../Processes/CheckInProcess/types';
import printer from '../../assets/printer.png';

import { CheckinConfirmationStyles } from '../CheckinConfirmation/CheckinConfirmation.styles';
import { getTicketContent } from '../CheckinConfirmation/TicketTemplate/ticketTemplate';
import checkinMessages from '../CheckinConfirmation/messages';
import symptomCheckerMessages from '../SymptomCheckerScreen/messages';
import DebugPrinterWidget from '../DebugPrinterWidget/DebugPrinterWidget';
import { KBBWindow } from '../../types/printer';

type printTicketProps = {
	nextButtonLabel: {
		id: string;
		defaultMessage: string;
	};
	onClick?: () => void;
	data: null | CheckinConfirmationData | TriageOutcomeData;
};

const MOCK_PRINTER = process.env.REACT_APP_MOCK_PRINTER === 'TRUE';
const MockedPrinter = {
	isConnected: () => true,
	connect: () => true,
	sendBytes: (bytes: string) => {
		console.log(bytes);
		return true;
	},
};

declare let window: KBBWindow;

type BluetoothStatus = 'noEnvironment' | 'notConnected' | 'connecting' | 'connected' | 'printed' | 'error';

export const PrintTicket = ({ nextButtonLabel, onClick, data }: printTicketProps): JSX.Element => {
	const isCheckinConfirmationData = data && 'dateOfToday' in data;
	const isTriageOutcomeData = data && 'conversation' in data;
	const { formatMessage } = useIntl();
	const [confirmationStatus, setConfirmationStatus] = useState('notConnected' as BluetoothStatus);

	function handleTicketPrint() {
		const KBBluetooth = MOCK_PRINTER ? MockedPrinter : window?.KBBluetooth;
		if (KBBluetooth) {
			if (KBBluetooth.isConnected()) {
				setConfirmationStatus('connected');
				const printResult = receiptPrint();
				if (printResult) {
					setConfirmationStatus('printed');
				} else {
					setConfirmationStatus('error');
				}
			} else {
				setConfirmationStatus('error');
			}
		} else {
			setConfirmationStatus('noEnvironment');
		}
	}

	useEffect(() => {
		if (data) handleTicketPrint();
	}, []);

	const printLine = (line: string): boolean => {
		const KBBluetooth = MOCK_PRINTER ? MockedPrinter : window.KBBluetooth;
		const result = KBBluetooth.sendBytes(line);
		return result !== false;
	};

	function receiptPrint(): boolean {
		const lines = data ? getTicketContent({ ...data }) : [];
		const results = lines.map(printLine);
		return results.every((line) => line);
	}

	const getPrintingMessage = (status: BluetoothStatus) => {
		switch (status) {
			case 'noEnvironment':
				return checkinMessages.printerNoEnvironment;
			case 'notConnected':
				return checkinMessages.printerNotConnected;
			case 'connected':
				return checkinMessages.printerConnected;
			case 'connecting':
				return checkinMessages.printerConnecting;
			case 'error':
				return checkinMessages.printerError;
			case 'printed':
				return checkinMessages.ticketPrinted;
		}
	};

	const getIcon = (status: BluetoothStatus): ReactElement =>
		status === 'notConnected' || status === 'error' || status === 'noEnvironment' ? (
			<CheckinConfirmationStyles.ErrorIcon />
		) : (
			<CheckinConfirmationStyles.CheckmarkIcon />
		);

	const printingStatus = () => {
		return (
			<>
				{getIcon(confirmationStatus)}
				<CheckinConfirmationStyles.Message variant="body">
					{formatMessage(getPrintingMessage(confirmationStatus))}
				</CheckinConfirmationStyles.Message>
			</>
		);
	};

	const { confirmationMessage, instructionTwoMessage } = isCheckinConfirmationData
		? {
				confirmationMessage: checkinMessages.confirmation,
				instructionTwoMessage: checkinMessages.instructionTwo,
		  }
		: {
				confirmationMessage: symptomCheckerMessages.confirmation,
				instructionTwoMessage: symptomCheckerMessages.instructionTwo,
		  };

	const getNotification = (status: BluetoothStatus) =>
		status === 'printed' ? (
			<Notification title={formatMessage(checkinMessages.instructionTitle)}>
				<List
					css=""
					listData={[formatMessage(checkinMessages.instructionOne), formatMessage(instructionTwoMessage)]}
					variant="ol"
				/>
			</Notification>
		) : null;

	function TriageOutcome({ data }: { data: TriageOutcomeData }) {
		const conversation = data.conversation;
		const title = formatMessage(symptomCheckerMessages.results);
		return <Notification title={title}>{conversation.triageOutcome}</Notification>;
	}

	return (
		<>
			<Container>
				<CheckinConfirmationStyles.Container>
					<CheckinConfirmationStyles.TitleWrapper>
						<CheckinConfirmationStyles.CheckmarkIcon />
						<CheckinConfirmationStyles.Message variant="body">
							{formatMessage(confirmationMessage)}
						</CheckinConfirmationStyles.Message>
					</CheckinConfirmationStyles.TitleWrapper>
					<CheckinConfirmationStyles.PrinterIcon src={printer} alt="" />
					<CheckinConfirmationStyles.TitleWrapper>{printingStatus()}</CheckinConfirmationStyles.TitleWrapper>
					{isTriageOutcomeData && <TriageOutcome {...{ data }} />}
					{getNotification(confirmationStatus)}
					<CheckinConfirmationStyles.TryAgainButton variant={ButtonVariant.secondary} onClick={handleTicketPrint}>
						{formatMessage(checkinMessages.tryAgain)}
					</CheckinConfirmationStyles.TryAgainButton>
					<CheckinConfirmationStyles.NextButton
						disabled={confirmationStatus === 'connecting' || confirmationStatus === 'connected'}
						onClick={onClick}
					>
						{formatMessage(nextButtonLabel)}
					</CheckinConfirmationStyles.NextButton>
				</CheckinConfirmationStyles.Container>
			</Container>
			<DebugPrinterWidget />
		</>
	);
};
