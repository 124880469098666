import { createSelector } from '@reduxjs/toolkit';

import { CheckinConfirmationData } from '../Processes/CheckInProcess/types';

import { RootState } from './store';

const selectCheckIn = (state: RootState) => state.checkIn;
const selectCatchmentArea = (state: RootState) => state.catchmentArea;

export const getPatientData = createSelector(
	[selectCheckIn, selectCatchmentArea],
	(checkIn, catchmentArea): null | CheckinConfirmationData => {
		switch (checkIn.step) {
			case 'CHECKIN_CONFIRMATION_SCREEN':
				return { ...checkIn.policy, ...catchmentArea };
			case 'SYMPTOM_CHECKER_START':
				if (checkIn.policy) {
					return { ...checkIn.policy, ...catchmentArea };
				} else {
					return null;
				}
			default:
				return null;
		}
	}
);
