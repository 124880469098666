import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { RootState } from '../../redux/store';
import { HCLoginPage } from '../../Views/HCLoginPage';
import { Spinner } from '../../components/Spinner';
import { translations } from '../../translations';
import { CheckInProcess } from '../CheckInProcess';
import { assertExhaustive } from '../assertExhaustive';

export const HCLoginProcess = (): JSX.Element => {
	const healthCentre = useSelector((state: RootState) => state.healthCentre);
	const { locale } = useSelector((state: RootState) => state.locale);

	const renderStep = () => {
		switch (healthCentre.step) {
			case 'LOADING': {
				return <Spinner />;
			}
			case 'HC_LOGIN_SUCCESSFUL': {
				// return <Redirect to="/language" />; if we want to use routes
				return <CheckInProcess />;
			}
			case 'HC_LOGIN_FORM': {
				return <HCLoginPage errors={healthCentre.errors} />;
			}
			default: {
				assertExhaustive(healthCentre);
			}
		}
	};

	return (
		<IntlProvider locale={locale} messages={translations[locale]}>
			{renderStep()}
		</IntlProvider>
	);
};
