import { FormEvent } from 'react';
import { Label } from '@babylon/medkit';
import { MaskedInputStyles } from './MaskedInput.styles';

type MaskedInputProps = {
	value: string;
	name: string;
	label: string;
	mask: string;
	maskChar?: string | null;
	alwaysShowMask?: boolean;
	error?: boolean;
	onChange: (event: FormEvent<HTMLInputElement>) => void;
	onBlur?: (event: FormEvent<HTMLInputElement>) => void;
	id?: string;
};

export const MaskedInput = ({
	value,
	label,
	name,
	mask,
	maskChar,
	alwaysShowMask = false,
	error = false,
	onChange,
	onBlur,
	id,
}: MaskedInputProps): JSX.Element => {
	return (
		<MaskedInputStyles.InputWrapper>
			<Label htmlFor={name} label={label} />
			<MaskedInputStyles.Input
				id={id}
				name={name}
				mask={mask}
				maskChar={maskChar}
				alwaysShowMask={alwaysShowMask}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				error={error}
			/>
		</MaskedInputStyles.InputWrapper>
	);
};
