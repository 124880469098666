import { useEffect, useState } from 'react';
import { useModalActions, ModalContent } from '@babylon/medkit';
import ErrorMessages from '@babylon/medkit/lib/Forms/ErrorMessages';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Container } from '../../components/Container';
import { AppError, PatientData } from '../../Processes/CheckInProcess/types';
import { NidInput } from '../../components/NidInput';
import { trimSpaces } from '../../utils/trimSpaces';
import generalMessages from '../messages';
import errorMessages from '../ErrorScreen/messages';
import httpMessages from '../../components/HttpErrorFallback/messages';
import { RootState } from '../../redux/store';

import { PatientDetailsStyles } from './PatientDetails.styles';
import messages from './messages';
import { TermsAndConditions } from './components/TermsAndConditions';

type PatientDetailsProps = {
	onSubmitData?: (patientData: PatientData) => void;
	errors?: AppError;
};

export const PatientDetails = ({ onSubmitData = () => null, errors }: PatientDetailsProps): JSX.Element => {
	const { locale } = useSelector((state: RootState) => state.locale);
	const { formatMessage } = useIntl();
	const { closeModal, openModal } = useModalActions();
	const [nationalId, setNationalId] = useState('');
	const [termsConsent, setTermsConsent] = useState(false);
	const [noMatchError, setNoMatchError] = useState<string[]>([]);

	const showModal = () => {
		openModal(
			<ModalContent stickyButtons={false} title={formatMessage(messages.termsTitle)}>
				<TermsAndConditions />
				<PatientDetailsStyles.CloseButtonContainer>
					<PatientDetailsStyles.CloseButton onClick={closeModal}>
						{formatMessage(generalMessages.close)}
					</PatientDetailsStyles.CloseButton>
				</PatientDetailsStyles.CloseButtonContainer>
			</ModalContent>
		);
	};

	const handleNationalIdChange = (value: string) => setNationalId(value);

	const onValidateInputs = () => {
		if (!nationalId) {
			setNoMatchError([formatMessage(messages.missingNid)]);
		}
	};

	const handleSubmit = (patientData: PatientData) => {
		if (nationalId) {
			onSubmitData(patientData);
		} else {
			onValidateInputs();
		}
	};

	useEffect(() => {
		if (errors?.status === 404 || errors?.status === 400) {
			setNoMatchError([formatMessage(messages.noMatch)]);
		}
		if (errors?.status === 500) {
			setNoMatchError([formatMessage(errorMessages.generalError), formatMessage(httpMessages.tryAgain)]);
		}
	}, [errors, locale]);

	useEffect(() => {
		if (noMatchError.length) {
			onValidateInputs();
		}
	}, [locale]);

	return (
		<Container>
			<PatientDetailsStyles.Container>
				<PatientDetailsStyles.Header variant="h3">{formatMessage(messages.callToAction)}</PatientDetailsStyles.Header>
				<PatientDetailsStyles.BackendError>
					<ErrorMessages formErrors={noMatchError} />
				</PatientDetailsStyles.BackendError>
				<PatientDetailsStyles.FormContainer>
					<PatientDetailsStyles.FormElement>
						<NidInput
							label={formatMessage(messages.nidLabel)}
							onBlur={(nid) => {
								handleNationalIdChange(nid);
							}}
							error={Boolean(!nationalId) && Boolean(noMatchError.length)}
						/>
					</PatientDetailsStyles.FormElement>
					<PatientDetailsStyles.FormElement>
						<PatientDetailsStyles.ConsentCheckbox
							checked={termsConsent}
							onChange={() => setTermsConsent(!termsConsent)}
						>
							{formatMessage(messages.consent)}
						</PatientDetailsStyles.ConsentCheckbox>
					</PatientDetailsStyles.FormElement>
				</PatientDetailsStyles.FormContainer>
				<PatientDetailsStyles.LearnMoreButton onClick={showModal}>
					{formatMessage(messages.learnMore)}
				</PatientDetailsStyles.LearnMoreButton>
				<PatientDetailsStyles.ContinueButton
					disabled={!termsConsent}
					onClick={() => handleSubmit({ patientNID: trimSpaces(nationalId) })}
				>
					{formatMessage(generalMessages.continue)}
				</PatientDetailsStyles.ContinueButton>
			</PatientDetailsStyles.Container>
		</Container>
	);
};
