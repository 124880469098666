import axios, { AxiosResponse } from 'axios';

import { HcLoginResponse, LoginResult, LoginType, StaffCredentials } from '../../Processes/HCLoginProcess/types';
import { tokenExpirationSeconds } from '../../Processes/session';

const hcApiUrl = process.env.REACT_APP_DOMAIN + '/api/v1';

const postLogin = async (email: string, password: string): Promise<AxiosResponse<HcLoginResponse>> => {
	const loginType: LoginType = 'health_centre';
	return await axios.post(`${hcApiUrl}/sessions`, {
		email,
		password,
		login_type: loginType,
	});
};

export const postLoginService = async (credentials: StaffCredentials): Promise<LoginResult> => {
	const { email, password } = credentials;
	const response = await postLogin(email, password);
	const tokenMaxAge = response.headers['x-access-token-expires'] || `${tokenExpirationSeconds}`;
	return {
		token: response.headers['x-access-token'],
		tokenMaxAge,
		healthCentreId: response.data.uuid,
		healthCentreName: response.data.name,
	};
};
