import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
	AppError,
	CheckinConfirmationData,
	CheckinProcessState,
	PatientCheckinData,
	RegistrationData,
} from '../../Processes/CheckInProcess/types';
import { RootState } from '../store';

import { fetchInsuranceDetails } from './fetchInsuranceDetails';
import { fetchRegistrationDetails } from './fetchRegistrationDetails';
import { handleRegistrationErrors } from './handleRegistrationErrors';

export const BASENAME = 'checkIn';

const initialState = {
	step: 'LANGUAGE_SELECTION_SCREEN',
} as CheckinProcessState;

const checkInSlice = createSlice({
	name: BASENAME,
	initialState,
	reducers: {
		setIdentityCardScannerScreenStep: (_) => ({ step: 'IDENTITY_CARD_SCANNER_SCREEN' }),
		setCatchmentAreaScreenStep: (_) => ({ step: 'CATCHMENT_AREA_SCREEN' }),
		setPatientDetailsScreenStep: (_, action) => ({ step: 'PATIENT_DETAILS_SCREEN', errors: action.payload }),
		setPatientRegistrationDataStep: (state, action: { payload: RegistrationData }) => ({
			step: 'PATIENT_REGISTRATION_DATA',
			patient: { ...action.payload },
		}),
		setHohDetailsScreenStep: (state, action: { payload: RegistrationData }) => ({
			step: 'HOH_DETAILS_SCREEN',
			patient: { ...action.payload },
		}),
		setCheckinErrorScreenStep: (state, action: { payload: AppError }) => {
			return { step: 'CHECKIN_ERROR_SCREEN', errors: { ...action.payload } };
		},
		setCheckinProcessFinalizedStep: (_) => ({ step: 'CHECKIN_PROCESS_FINALIZED' }),
		restartRegistrationProcess: (_) => ({ step: 'LANGUAGE_SELECTION_SCREEN' }),
		setLoadingStep: (_) => ({ step: 'LOADING' }),
		setSymptomCheckerStart: (state, action: { payload: null | CheckinConfirmationData }) => ({
			step: 'SYMPTOM_CHECKER_START',
			policy: action?.payload ? { ...action.payload } : null,
		}),
	},
	extraReducers: (builder) => {
		builder.addCase(fetchRegistrationDetails.pending, (_) => ({ step: 'LOADING' }));
		builder.addCase(fetchRegistrationDetails.rejected, (state, action) => {
			if (state.step === 'LOADING' && action.payload) {
				return handleRegistrationErrors(action.payload);
			}
		});
		builder.addCase(fetchInsuranceDetails.fulfilled, (state, action: { payload: PatientCheckinData }) => ({
			step: 'CHECKIN_CONFIRMATION_SCREEN',
			policy: { ...action.payload },
		}));
	},
});

export const selectStep = createSelector(
	(state: RootState): CheckinProcessState => state.checkIn,
	(checkIn) => checkIn.step
);

export const { reducer, actions: checkInActions } = checkInSlice;
