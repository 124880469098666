import { AppError, CheckinProcessState } from '../../Processes/CheckInProcess/types';
import { networkError } from '../../api/mockApi/mocks';

export const handleRegistrationErrors = (payload: AppError): CheckinProcessState => {
	switch (payload.status) {
		case 400:
		case 404: {
			return {
				step: 'PATIENT_DETAILS_SCREEN',
				errors: { ...payload },
			};
		}
		default: {
			return {
				step: 'PATIENT_DETAILS_SCREEN',
				errors: { ...networkError },
			};
		}
	}
};
