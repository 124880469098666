import { Gender } from '../types/unions';

import { extractNid } from './extractNid';

export const getGender = (identifier: string): Gender => {
	const nid = extractNid(identifier);
	const genderCode = nid.split('')[5];
	if (genderCode === '7') return 'F';
	return 'M';
};
