import * as tldjs from 'tldjs';

export const getDomainWithoutSubdomain = (url: string): string | null => {
	const valid = tldjs.tldExists(url);
	const domain = tldjs.getDomain(url);
	if (valid && domain) {
		return domain;
	} else {
		console.error(`Failed to parse url ${url}`);
		return null;
	}
};
