import styled from 'styled-components';
import { Text, LinkButton } from '@babylon/medkit';

import { CardTarget } from './assets/CardTarget';

const Consent = styled(Text)`
	font-size: 0.8rem;
	font-weight: 300;
	line-height: 1.1rem;
	margin: 0 0.5rem 0.5rem 1rem;
`;

type StyledCardTargetProps = {
	landscape?: boolean;
};

const InstructionsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	margin: 0.5rem;
`;

const Instructions = styled.div`
	margin: 0 1.5rem;
	max-width: 500px;

	ol {
		margin: 0.5rem 0;
	}
`;

const InstructionsHeader = styled(Text)`
	font-size: 1.75rem;
	font-weight: 300;
`;

const InstructionsList = styled.ol`
	font-size: 1.25rem;
	font-weight: 300;
`;

const FallbackContainer = styled.div`
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: center;
	width: 100%;
`;

const ScannerContainer = styled.div`
	width: 100%;
	min-height: 0;
	overflow: hidden;
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;

	video {
		object-fit: cover;
		width: 200%;
	}
`;

const StyledCardTarget = styled(CardTarget)<StyledCardTargetProps>`
	height: ${({ landscape }) => (landscape ? '95%' : 'auto')};
	position: absolute;
	width: ${({ landscape }) => (landscape ? 'auto' : '95%')};
	z-index: 100;
`;

const StyledCardHolder = styled.img`
	height: 200px;
	width: auto;
`;

const StyledLinkButton = styled(LinkButton)`
	z-index: 999;
`;

export const IdentityCardScannerStyles = {
	Consent,
	InstructionsContainer,
	Instructions,
	InstructionsHeader,
	InstructionsList,
	FallbackContainer,
	ScannerContainer,
	StyledCardTarget,
	StyledCardHolder,
	StyledLinkButton,
};
