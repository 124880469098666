import { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ErrorMessages from '@babylon/medkit/lib/Forms/ErrorMessages';

import { NidMask } from '../../Views/constants';
import { MaskedInput } from '../MaskedInput';

import messages from './messages';

type NidInputProps = {
	label: string;
	onBlur: (nid: string) => void;
	error: boolean;
};

const trimNonDigits = (value: string) => {
	const notADigit = /\D/g;
	return value.replace(notADigit, '');
};

export const NidInput = ({ label, onBlur, error }: NidInputProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const [nationalId, setNationalId] = useState('');
	const [frontendValidationError, setFrontendValidationError] = useState('');

	const handleNationalIdChange = (event: FormEvent<HTMLInputElement>) =>
		setNationalId(trimNonDigits(event.currentTarget.value));

	const isNidValid = (value: string): boolean => {
		const nidLength = 16;
		const trimmedValue = trimNonDigits(value);
		return trimmedValue.length === nidLength;
	};

	const addValidationError = () => {
		setFrontendValidationError(formatMessage(messages.nidValidationErrorMessage));
	};

	const handleBlur = (value: string) => {
		if (isNidValid(value)) {
			setFrontendValidationError('');
			onBlur(value);
		} else {
			addValidationError();
			onBlur('');
		}
	};

	useEffect(() => {
		if (frontendValidationError) addValidationError();
	}, [error, label]);

	return (
		<div>
			<MaskedInput
				label={label}
				value={nationalId}
				name="NID"
				id="NID"
				mask={NidMask}
				alwaysShowMask
				onChange={handleNationalIdChange}
				onBlur={(event) => {
					handleBlur(event.currentTarget.value);
				}}
				error={error || Boolean(frontendValidationError)}
			/>
			{frontendValidationError && <ErrorMessages formErrors={[frontendValidationError]} />}
		</div>
	);
};
