import axios, { AxiosResponse } from 'axios';

import { NID, PatientData, UrnNid } from '../../Processes/CheckInProcess/types';
import { PostRegistrationResponse } from '../../types/contracts';

const checkinApiUrl = process.env.REACT_APP_DOMAIN + '/api/health_centres/patients';

const getUrnNid = (nid: NID): UrnNid => `urn:babylon:rwa:org:nida:nid:${nid}`;

export const postRegister = async (
	patientData: PatientData,
	token: string
): Promise<AxiosResponse<PostRegistrationResponse>> =>
	await axios.post(
		`${checkinApiUrl}/register`,
		{
			identifier: getUrnNid(patientData.patientNID),
		},
		{
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			responseType: 'json',
		}
	);
