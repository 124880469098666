import { AxiosError } from 'axios';
import { AppError, LoginError } from '../Processes/CheckInProcess/types';

export const getAppErrorMessage = (data: AppError): string => (data.message ? data.message : '');

export const getAppError = (error: AxiosError): AppError => error.response?.data as AppError;

export const getLoginError = (error: AxiosError): LoginError => error.response?.data as LoginError;

// eslint-disable-next-line
export const isAxiosError = (error: any): error is AxiosError<unknown> => {
	return Boolean(error.isAxiosError) && typeof error.config == 'object';
};
