import { TimelogEvent } from '../../Processes/CheckInProcess/types';

export const timeLogEvents: Record<PropertyKey, TimelogEvent | ((value: string) => TimelogEvent)> = {
	initSession: {
		event_name: 'CHECK-IN-STARTED',
		event_value: '',
	},
	abandonSession: {
		event_name: 'CHECK-IN-ABANDONED',
		event_value: 'SESSION-EXPIRED',
	},
	setAppName: (value: string) => ({
		event_name: 'CLIENT-APPLICATION',
		event_value: `${value}`,
	}),
	setAppVersion: (value: string) => ({
		event_name: 'CLIENT-APPLICATION-VERSION',
		event_value: `${value}`,
	}),
	setHealthCentreId: (value: string) => ({
		event_name: 'SET-HEALTH-CENTRE-ID',
		event_value: `${value}`,
	}),
	setRegisterResult: (value: string) => ({
		event_name: 'REGISTRATION-CHECK',
		event_value: `${value}`,
	}),
	setAgeRange: (value: string) => ({
		event_name: 'PATIENT-AGE-RANGE',
		event_value: `${value}`,
	}),
	setGender: (value: string) => ({
		event_name: 'PATIENT-GENDER',
		event_value: `${value}`,
	}),
	setInsuranceResult: (value: string) => ({
		event_name: 'INSURANCE-CHECK',
		event_value: `${value}`,
	}),
};
