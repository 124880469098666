import { useEffect } from 'react';
import { BrowserMultiFormatReader, DecodeHintType, Result, BarcodeFormat } from '@zxing/library';

export const BarCodeScanner = ({
	onUpdate,
}: {
	onUpdate(err: unknown, result: Result | undefined): void;
}): JSX.Element => {
	const hints = new Map();
	const formats = [BarcodeFormat.PDF_417];
	hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
	hints.set(DecodeHintType.TRY_HARDER, true);
	const codeReader = new BrowserMultiFormatReader(hints);

	useEffect(() => {
		if (codeReader.canEnumerateDevices) {
			codeReader.listVideoInputDevices().then((cameras) => {
				const frontCamera: MediaDeviceInfo | undefined = cameras.find((camera) => camera.label.includes('front'));
				codeReader
					.decodeOnceFromVideoDevice(frontCamera?.deviceId, 'video')
					.then((result) => {
						onUpdate(null, result);
					})
					.catch((e) => {
						onUpdate(e, undefined);
					});
			});
		}

		return (): void => {
			codeReader.reset();
		};
	}, [codeReader]);

	return <video id="video" className="dbrScanner-video" playsInline />;
};
