import styled from 'styled-components';
import { ButtonSimple } from '@babylon/medkit';

import { ArrowLeft } from './assets/ArrowLeft';

const Container = styled.div`
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
	display: flex;
	height: 90px;
	justify-content: space-between;
	padding: 0 2rem;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
`;

const Header = styled.div`
	font-size: 1.25rem;
`;

const LanguageContainer = styled.div`
	align-items: flex-start;
	display: flex;
	font-size: 1rem;
	justify-content: flex-end;
	width: 15rem;

	img {
		margin-right: 0.5rem;
	}
`;

const BackButton = styled(ButtonSimple)`
	min-width: 7rem;
	width: 7rem;
`;

const ButtonContainer = styled.div`
	width: 15rem;
`;

const StyledArrowLeft = styled(ArrowLeft)`
	margin-left: 0.5rem;
`;

export const TopBarStyles = {
	Container,
	Header,
	LanguageContainer,
	BackButton,
	ButtonContainer,
	StyledArrowLeft,
};
