import styled from 'styled-components';
import InputMask from 'react-input-mask';

type InputProps = {
	error: boolean;
	maskChar: string | undefined | null;
	alwaysShowMask?: boolean;
};

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: auto;
	margin-right: 1rem;
`;

const normalBoxShadow = (error: boolean) =>
	error
		? `inset 0 0 0 var(--medkit-input-border-width, 0.125rem) var(--medkit-input-box-shadow-color-error-active, #c72e15)`
		: `inset 0rem 0rem 0rem var(--medkit-input-border-width, .125rem) var(--medkit-input-box-shadow-color, #728492)`;

const focusedBoxShadow = (error: boolean) =>
	error
		? `inset 0 0 0 var(--medkit-input-border-width, .125rem) var(--medkit-input-box-shadow-color-error-active, #c72e15)`
		: `inset 0 0 0 var(--medkit-input-border-width, .125rem) var(--medkit-input-box-shadow-color-active, #400099);`;

const focusedBorderColor = (error: boolean) =>
	error ? 'var(--medkit-input-border-color-error-active, #db7866)' : 'var(--medkit-input-border-color-active, #987ac3)';

const Input = styled(InputMask)<InputProps>`
	min-width: fit-content;
	width: 100%;
	max-width: calc(100% - 2.25rem);
	min-height: var(--medkit-input-height, 3rem);
	padding-right: 1rem;
	padding-left: 1rem;
	border-width: var(--medkit-input-border-width, 0.25rem);
	border-style: solid;
	border-color: transparent;
	margin-left: calc(0rem - var(--medkit-input-border-width, 0.25rem));
	border-radius: var(--medkit-input-border-radius, 0);
	font-size: var(--medkit-input-font-size, var(--medkit-base-font-size, 1rem));
	background-color: var(--medkit-input-background, #ffffff);
	box-shadow: ${(props) => normalBoxShadow(props.error)};
	&:focus {
		border-color: ${(props) => focusedBorderColor(props.error)};
		box-shadow: ${(props) => focusedBoxShadow(props.error)};
		outline: none;
	}
`;

export const MaskedInputStyles = {
	Input,
	InputWrapper,
};
