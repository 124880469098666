import styled from 'styled-components';
import { ButtonSimple, Text } from '@babylon/medkit';

import { ExclamationIcon } from './components/ExclamationIcon';

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 100%;
`;

const ErrorIcon = styled(ExclamationIcon)`
	margin-right: 0.5rem;
`;

const TitleWrapper = styled.div`
	align-items: center;
	display: inline-flex;
	margin: 3rem 0;
`;
const Message = styled(Text)`
	font-size: 1.75rem;
	font-weight: 300;
`;
const OkButton = styled(ButtonSimple)`
	margin-top: 5rem;
	width: 37%;
`;

export const ErrorScreenStyles = {
	Container,
	ErrorIcon,
	Message,
	OkButton,
	TitleWrapper,
};
