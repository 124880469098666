import { Cookies } from 'react-cookie';

import { LoginResult } from './HCLoginProcess/types';
import { getDomainWithoutSubdomain } from '../utils/getDomainWithoutSubdomain';

const cookies = new Cookies();
const hcSession = '---hc-session---';
export const tokenExpirationSeconds = 4 * 60 * 60;
export const hcAuthToken = 'hc_auth_token';

const logout = () => {
	window.sessionStorage.removeItem(hcSession);
	cookies.remove(hcAuthToken);
	location.reload();
};

export const autoRefreshOnCookieExpiration = (expiration: number): void => {
	const expirationTime = expiration - Date.now();
	setTimeout(logout, expirationTime);
};

export const setSession = (response: LoginResult): void => {
	const { token, tokenMaxAge, healthCentreId, healthCentreName } = response;
	const currentDate = Date.now();
	const expirationTime = parseInt(tokenMaxAge) * 1000;
	const expires = currentDate + expirationTime;
	const sessionData = { token, expires, healthCentreId, healthCentreName };
	window.sessionStorage.setItem(hcSession, JSON.stringify(sessionData));
	const curentUrl = location.href;
	const domain = getDomainWithoutSubdomain(curentUrl);
	const expiresDate = new Date(expires);
	if (domain) {
		cookies.set(hcAuthToken, token, { domain, expires: expiresDate });
	} else {
		cookies.set(hcAuthToken, token, { expires: expiresDate });
	}

	autoRefreshOnCookieExpiration(expires);
};

export const getSessionStorageData = (): {
	token: string;
	expires: number;
	healthCentreId: string;
	healthCentreName: string;
} | null => {
	const sessionData = window.sessionStorage.getItem(hcSession);
	if (sessionData) {
		const parsedSessionData = JSON.parse(sessionData);
		if (parsedSessionData.token) {
			return parsedSessionData;
		}
	}
	return null;
};

const getHealthCentreToken = (): string | null => {
	return cookies.get(hcAuthToken);
};

export const getSessionIsResumable = (): boolean => {
	return Boolean(getHealthCentreToken()) && Boolean(getSessionStorageData());
};
