import { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M31.5 18C31.5 25.4559 25.4559 31.5 18 31.5C10.5441 31.5 4.5 25.4559 4.5 18C4.5 10.5441 10.5441 4.5 18 4.5C25.4559 4.5 31.5 10.5441 31.5 18ZM16.4385 25.1481L26.4546 15.132C26.7947 14.7919 26.7947 14.2404 26.4546 13.9003L25.2229 12.6686C24.8828 12.3284 24.3313 12.3284 23.9911 12.6686L15.8226 20.8371L12.0089 17.0234C11.6688 16.6833 11.1173 16.6833 10.7771 17.0234L9.54541 18.2551C9.20529 18.5952 9.20529 19.1467 9.54541 19.4868L15.2067 25.1481C15.5469 25.4883 16.0983 25.4883 16.4385 25.1481Z"
			fill="#2DC76D"
		/>
	</svg>
);
