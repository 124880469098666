import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
	<svg fill="none" width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12.3867 1C6.31172 1 1.38672 5.925 1.38672 12C1.38672 18.075 6.31172 23 12.3867 23C18.4617 23 23.3867 18.075 23.3867 12C23.3867 5.925 18.4617 1 12.3867 1ZM12.3867 18C11.8347 18 11.3867 17.552 11.3867 17V10C11.3867 9.448 11.8347 9 12.3867 9C12.9387 9 13.3867 9.448 13.3867 10V17C13.3867 17.552 12.9387 18 12.3867 18ZM12.3867 8C11.6967 8 11.1367 7.44 11.1367 6.75C11.1367 6.06 11.6967 5.5 12.3867 5.5C13.0767 5.5 13.6367 6.06 13.6367 6.75C13.6367 7.44 13.0767 8 12.3867 8Z"
			fill="#326FEC"
		/>
	</svg>
);
