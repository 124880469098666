import { useDispatch, useSelector } from 'react-redux';
import { ButtonVariant, LinkButton } from '@babylon/medkit';
import { useIntl } from 'react-intl';

import { Languages } from '../../types/enums';
import { selectLocale } from '../../redux/Locale/locale.slice';
import { selectStep } from '../../redux/CheckIn/checkIn.slice';
import { catchmentAreaActions } from '../../redux/CatchmentArea/catchmentArea.slice';
import RwandaFlag from '../../assets/rwanda_flag-icon_round.svg';
import UKFlag from '../../assets/united-kingdom_flag_icon_round.svg';
import { checkInActions } from '../../redux/CheckIn/checkIn.slice';
import { setLocale } from '../../redux/Locale/locale.slice';
import generalMessages from '../../Views/messages';
import engLocale from '../../translations/en.json';
import rwLocale from '../../translations/rw.json';

import { TopBarStyles } from './TopBar.styles';

export const TopBar = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const locale = useSelector(selectLocale);
	const stepName = useSelector(selectStep);
	const backToLanguageScreen = () => {
		dispatch(checkInActions.restartRegistrationProcess());
		dispatch(catchmentAreaActions.setDefault());
	};
	const backToCatchmentArea = () => {
		dispatch(checkInActions.setCatchmentAreaScreenStep());
	};
	// todo restore when scanning solution ready
	// const backToScannerScreen = () => {
	// 	dispatch(registrationActions.setIdentityCardScannerScreenStep());
	// };
	const setLanguage = (locale: string) => {
		dispatch(setLocale(locale));
	};
	const renderLanguageInfo = () => {
		if (stepName !== 'LANGUAGE_SELECTION_SCREEN') {
			if (locale === Languages.RW) {
				return (
					<>
						<img src={UKFlag} alt="" />
						<LinkButton onClick={() => setLanguage(Languages.EN)}>{engLocale['General.english']}</LinkButton>
					</>
				);
			}
			if (locale === Languages.EN) {
				return (
					<>
						<img src={RwandaFlag} alt="" />
						<LinkButton onClick={() => setLanguage(Languages.RW)}>{rwLocale['General.kinyarwandan']}</LinkButton>
					</>
				);
			}
		}
	};
	const renderBackButton = () => {
		if (stepName === 'IDENTITY_CARD_SCANNER_SCREEN')
			return (
				<TopBarStyles.BackButton variant={ButtonVariant.secondary} onClick={backToLanguageScreen}>
					{formatMessage(generalMessages.back)}
					<TopBarStyles.StyledArrowLeft />
				</TopBarStyles.BackButton>
			);
		if (stepName === 'PATIENT_DETAILS_SCREEN')
			return (
				<TopBarStyles.BackButton variant={ButtonVariant.secondary} onClick={backToCatchmentArea}>
					{formatMessage(generalMessages.back)}
					<TopBarStyles.StyledArrowLeft />
				</TopBarStyles.BackButton>
			);
		if (stepName === 'CATCHMENT_AREA_SCREEN')
			return (
				<TopBarStyles.BackButton variant={ButtonVariant.secondary} onClick={backToLanguageScreen}>
					{formatMessage(generalMessages.back)}
					<TopBarStyles.StyledArrowLeft />
				</TopBarStyles.BackButton>
			);
	};

	return (
		<TopBarStyles.Container>
			<TopBarStyles.ButtonContainer>{renderBackButton()}</TopBarStyles.ButtonContainer>
			<TopBarStyles.Header>{formatMessage(generalMessages.topbarTitle)}</TopBarStyles.Header>
			<TopBarStyles.LanguageContainer>{renderLanguageInfo()}</TopBarStyles.LanguageContainer>
		</TopBarStyles.Container>
	);
};
