import { Languages } from '../../types/enums';
import { Container } from '../../components/Container';
import heart from '../../assets/heart.png';
import engLocale from '../../translations/en.json';
import rwLocale from '../../translations/rw.json';
import { ExtractedTranslations } from '../../Processes/CheckInProcess/types';

import { LanguagePageStyles } from './LanguagePage.styles';
import { SymptomCheckerStartPath } from '../../Router';
import createUrl from '../../utils/createUrl';
import DebugPrinterWidget from '../DebugPrinterWidget/DebugPrinterWidget';

type LanguageChoiceProps = {
	onSelectLanguage: (locale: Languages) => void;
};

export const LanguagePage = ({ onSelectLanguage }: LanguageChoiceProps): JSX.Element => {
	const renderLanguageSelection = (locale: Languages, translations: ExtractedTranslations) => (
		<LanguagePageStyles.LanguageSelection>
			<LanguagePageStyles.Heart src={heart} alt="" />
			<LanguagePageStyles.CallToAction variant={'body'}>
				{translations['LanguagePage.label']}
			</LanguagePageStyles.CallToAction>
			<LanguagePageStyles.LanguageButton onClick={() => onSelectLanguage(locale)}>
				<LanguagePageStyles.ButtonLabel>{translations['LanguagePage.button']}</LanguagePageStyles.ButtonLabel>
			</LanguagePageStyles.LanguageButton>
		</LanguagePageStyles.LanguageSelection>
	);

	const handleClick = (locale: Languages) => {
		window.location.href = createUrl(SymptomCheckerStartPath, { locale });
	};

	return (
		<>
			<Container>
				<LanguagePageStyles.LanguageSelectionGroups>
					{renderLanguageSelection(Languages.RW, rwLocale)}
					{renderLanguageSelection(Languages.EN, engLocale)}
				</LanguagePageStyles.LanguageSelectionGroups>
				<LanguagePageStyles.ButtonsContainer>
					<LanguagePageStyles.SymptomCheckerButton onClick={() => handleClick(Languages.RW)}>
						<LanguagePageStyles.ButtonLabel>{rwLocale['General.symptomCheckerLabel']}</LanguagePageStyles.ButtonLabel>
					</LanguagePageStyles.SymptomCheckerButton>
					<LanguagePageStyles.SymptomCheckerButton onClick={() => handleClick(Languages.EN)}>
						<LanguagePageStyles.ButtonLabel>{engLocale['General.symptomCheckerLabel']}</LanguagePageStyles.ButtonLabel>
					</LanguagePageStyles.SymptomCheckerButton>
				</LanguagePageStyles.ButtonsContainer>
			</Container>
			<DebugPrinterWidget />
		</>
	);
};
