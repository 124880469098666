import { defineMessages } from 'react-intl';

export default defineMessages({
	confirmation: {
		id: 'SuccessPage.confirmation',
		defaultMessage: 'Insurance validated',
	},
	tryAgain: {
		id: 'SuccessPage.retryPrint',
		defaultMessage: 'Retry Print',
	},
	printerConnected: {
		id: 'SuccessPage.printerConnected',
		defaultMessage: 'Printer connected',
	},
	printerConnecting: {
		id: 'SuccessPage.printerConnecting',
		defaultMessage: 'Connecting Printer…',
	},
	printerNotConnected: {
		id: 'SuccessPage.printerNotConnected',
		defaultMessage: 'Printer not connected',
	},
	printerNoEnvironment: {
		id: 'SuccessPage.printerNoEnvironment',
		defaultMessage: 'Printing environment not found',
	},
	printerError: {
		id: 'SuccessPage.printerError',
		defaultMessage: 'Printer error',
	},
	ticketPrinted: {
		id: 'SuccessPage.ticketPrinted',
		defaultMessage: 'Your receipt is printed!',
	},
	instructionTitle: {
		id: 'SuccessPage.instructionTitle',
		defaultMessage: 'Next steps',
	},
	instructionOne: {
		id: 'SuccessPage.instructionOne',
		defaultMessage: 'Take your receipt from the printer',
	},
	instructionTwo: {
		id: 'SuccessPage.instructionTwo',
		defaultMessage: 'Use it to pay your admin fee.',
	},
});
