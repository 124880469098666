import { useState } from 'react';
import { useIntl } from 'react-intl';

import { NID } from '../../Processes/CheckInProcess/types';
import { Container } from '../../components/Container';
import { NidInput } from '../../components/NidInput';
import { trimSpaces } from '../../utils/trimSpaces';
import generalMessages from '../messages';

import { HouseholdDetailsStyles } from './HouseholdDetails.styles';

type HouseHoldDetailsProps = {
	onSubmitData?: (hohNID: NID) => void;
};

export const HouseholdDetails = ({ onSubmitData = () => null }: HouseHoldDetailsProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const [hohNID, setHohNID] = useState('');
	const [isHohInputPristine, setIsHohInputPristine] = useState(true);
	const handleNationalIdChange = (value: string) => {
		setHohNID(trimSpaces(value));
		setIsHohInputPristine(false);
	};
	const handleSubmit = () => {
		setIsHohInputPristine(false);
		if (hohNID) onSubmitData(hohNID);
	};

	return (
		<Container>
			<HouseholdDetailsStyles.Container>
				<NidInput
					label={formatMessage(generalMessages.hohNidLabel)}
					onBlur={(nid) => {
						handleNationalIdChange(nid);
					}}
					error={!isHohInputPristine && Boolean(!hohNID)}
				/>
				<HouseholdDetailsStyles.Button onClick={handleSubmit}>
					{formatMessage(generalMessages.continue)}
				</HouseholdDetailsStyles.Button>
			</HouseholdDetailsStyles.Container>
		</Container>
	);
};
