import { SVGProps } from 'react';

export const CardTarget = (props: SVGProps<SVGSVGElement>): JSX.Element => (
	<svg width="524" height="356" viewBox="0 0 524 356" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<line x1="519" y1="58" x2="519" y2="1" stroke="white" strokeWidth="10" />
		<line x1="519" y1="58" x2="519" y2="1" stroke="white" strokeWidth="10" />
		<line x1="5" y1="298" x2="5.00001" y2="355" stroke="white" strokeWidth="10" />
		<line x1="5" y1="298" x2="5.00001" y2="355" stroke="white" strokeWidth="10" />
		<line x1="524" y1="5" x2="467" y2="5" stroke="white" strokeWidth="10" />
		<line x1="524" y1="5" x2="467" y2="5" stroke="white" strokeWidth="10" />
		<line x1="-4.37114e-07" y1="351" x2="57" y2="351" stroke="white" strokeWidth="10" />
		<line x1="-4.37114e-07" y1="351" x2="57" y2="351" stroke="white" strokeWidth="10" />
		<line x1="466.5" y1="351" x2="523.5" y2="351" stroke="white" strokeWidth="10" />
		<line x1="466.5" y1="351" x2="523.5" y2="351" stroke="white" strokeWidth="10" />
		<line x1="57.5" y1="5" x2="0.500001" y2="5.00001" stroke="white" strokeWidth="10" />
		<line x1="57.5" y1="5" x2="0.500001" y2="5.00001" stroke="white" strokeWidth="10" />
		<line x1="519" y1="356" x2="519" y2="299" stroke="white" strokeWidth="10" />
		<line x1="519" y1="356" x2="519" y2="299" stroke="white" strokeWidth="10" />
		<line x1="5" y1="-4.96738e-07" x2="5.00001" y2="57" stroke="white" strokeWidth="10" />
		<line x1="5" y1="-4.96738e-07" x2="5.00001" y2="57" stroke="white" strokeWidth="10" />
	</svg>
);
