import axios, { AxiosResponse } from 'axios';

import { TimeLogState } from '../../Processes/CheckInProcess/types';

const timeLogApiUrl = process.env.REACT_APP_DOMAIN + '/api/health_centres/events';

export const postTimeLog = async (
	timeLog: TimeLogState,
	token: string,
	resourceId: string,
	resourceName: string
): Promise<AxiosResponse> =>
	await axios.post(
		`${timeLogApiUrl}`,
		{ ...timeLog, resource_id: resourceId, resource_name: resourceName },
		{
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			responseType: 'json',
		}
	);
