import { PostCheckPolicyResponse, PostRegistrationResponse } from '../../types/contracts';
import { AppError, LoginError, ValidPolicy } from '../../Processes/CheckInProcess/types';
import { HcLoginResponse, LoginResult } from '../../Processes/HCLoginProcess/types';

export const mockLoginResponse: LoginResult = {
	token: '12ab34567890123c1d45e6fghi78jk90',
	tokenMaxAge: '60',
	healthCentreId: '01234567-012a-012a-ab0c-01ab23cd4567',
	healthCentreName: 'HC name',
};

export const mockLoginError: LoginError = {
	error_codes: ['not_found_in_database'],
	error_details: { base: [{ error: 'Invalid credentials, please try again' }] },
	errors: { base: ['Invalid credentials, please try again'] },
};

export const mockLoginSuccess: HcLoginResponse = {
	uuid: '01234567-012a-012a-ab0c-01ab23cd4567',
	id: 12345,
	name: 'HC name',
};

export const mockEmailError: AppError = {
	error: 'not valid email',
	message: 'not valid email',
	status: 'FE1',
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockCredentialsError: AppError = {
	error: 'please enter valid email and password',
	message: 'please enter valid email and password',
	status: '402',
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockLoginGeneralError: LoginError = {
	error_codes: ['internal_error'],
	error_details: { base: [{ error: 'Internal error, please try again' }] },
	errors: { base: ['Internal error, please try again'] },
};

export const mockPolicyResponse = (): PostCheckPolicyResponse => {
	const nid = Math.floor(Math.random() * 1000000000000001);
	return {
		insurance: {
			identifier: [
				{
					system: `urn:babylon:rwa:org:mutuelle:policy:${nid}`,
				},
				{
					system: 'urn:babylon:fhir_coverage:f6ddb1e2-5078-4488-b973-bb6590a299c9',
				},
			],
			status: 'active',
			policyHolder: {
				identifier: {
					system: `urn:babylon:rwa:org:nida:nid:${nid}`,
				},
				resource: {
					resourceType: 'Patient',
					name: [
						{
							use: 'official',
							text: 'Homer Simpson',
						},
					],
					gender: 'unknown',
				},
			},
			subscriber: {
				identifier: {
					system: 'urn:babylon:rwa:org:nida:nid:1198089430209675',
				},
				resource: {
					resourceType: 'Patient',
					name: [
						{
							use: 'official',
							text: 'Homer Simpson',
						},
					],
					gender: 'unknown',
				},
			},
			beneficiary: {
				identifier: {
					system: 'urn:babylon:rwa:org:nida:nid:1198089430209675',
				},
				resource: {
					resourceType: 'Patient',
					name: [
						{
							use: 'official',
							text: 'Homer Simpson',
						},
					],
					gender: 'unknown',
				},
			},
			period: {
				start: '2022-02-16T00:00:00',
				end: '2022-03-18T00:00:00',
			},
			payor: [
				{
					identifier: {
						system: 'urn:babylon:rwa:org:mutuelle:org:mutuelle',
					},
					resource: {
						resourceType: 'Organisation',
					},
				},
			],
			network: 'MUTUELLE',
		},
	};
};

export const mockPolicyGeneralError: AppError = {
	error: 'Unauthorized',
	message: 'Wrong email or password',
	status: 401,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockPolicyNotValidError: AppError = {
	error: 'Not Found',
	message: 'Policy ID not found',
	status: 400,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockPolicyExpiredError: AppError = {
	error: 'Not Found',
	message: 'Policy ID not found',
	status: 402,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockPolicyWeNeedMoreInformationError: AppError = {
	error: 'Not Found',
	message: 'Policy ID not found',
	status: 400,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockPolicyNotFoundError: AppError = {
	error: 'Not Found',
	message: 'Policy ID not found',
	status: 404,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const infrastructureError: AppError = {
	error: 'Internal error',
	message: 'Something went wrong',
	status: 500,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const networkError: AppError = {
	error: 'Internal error',
	message: 'Something went wrong',
	status: 500,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockDateOfBirthNotFoundResponse: AppError = {
	error: 'Not Found',
	message: 'Policy ID not found',
	status: 400,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockNidNotFoundResponse: AppError = {
	error: 'Bad Request',
	message: 'Insurance number is invalid',
	status: 404,
	timestamp: '2022-01-31T08:43:24.536180Z',
};

export const mockRegistrationSuccessResponse: PostRegistrationResponse = {
	dateOfBirth: '1990-01-01',
	firstName: 'Mary Anne',
	identifier: 'urn:babylon:rwa:org:nida:nid:1196772582584753',
	identityUrn: 'urn:babylon:rwa:identity:123e4567-e89b-12d3-a456-426614174000',
	lastName: 'de la Cruz',
	patientUrn: 'urn:babylon:rwa:patient:1234567',
};

export const sampleResponse: ValidPolicy = {
	insurance: {
		identifier: [
			{
				system: 'urn:babylon:rwa:org:mutuelle:policy:1198089430209675',
			},
			{
				system: 'urn:babylon:fhir_coverage:f6ddb1e2-5078-4488-b973-bb6590a299c9',
			},
		],
		status: 'active',
		policyHolder: {
			identifier: {
				system: 'urn:babylon:rwa:org:nida:nid:1198089430209675',
			},
			resource: {
				resourceType: 'Patient',
				name: [
					{
						use: 'official',
						text: 'Homer Simpson',
					},
				],
				gender: 'unknown',
			},
		},
		subscriber: {
			identifier: {
				system: 'urn:babylon:rwa:org:nida:nid:1198089430209675',
			},
			resource: {
				resourceType: 'Patient',
				name: [
					{
						use: 'official',
						text: 'Homer Simpson',
					},
				],
				gender: 'unknown',
			},
		},
		beneficiary: {
			identifier: {
				system: 'urn:babylon:rwa:org:nida:nid:1198089430209675',
			},
			resource: {
				resourceType: 'Patient',
				name: [
					{
						use: 'official',
						text: 'Homer Simpson',
					},
				],
				gender: 'unknown',
			},
		},
		period: {
			start: '2022-02-16T00:00:00',
			end: '2022-03-18T00:00:00',
		},
		payor: [
			{
				identifier: {
					system: 'urn:babylon:rwa:org:mutuelle:org:mutuelle',
				},
				resource: {
					resourceType: 'Organisation',
				},
			},
		],
		network: 'MUTUELLE',
		class: {
			type: 'group',
			value: 'CATEGORY_3',
		},
	},
};
